import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export class AuthAdminInput {
  email: Scalars['String'];
  password: Scalars['String'];
};

export class AuthInput {
  email: Scalars['String'];
  password: Scalars['String'];
};

export class AuthType {
  token: Scalars['String'];
  user: User;
};

export class AuthTypeAdmin {
  token: Scalars['String'];
  user: UserAdmin;
};

export class Bank {
  bankName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  icon: Scalars['String'];
  id: Scalars['ID'];
  maskAccount: Scalars['String'];
  maskAgency: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userInfoAccount?: Maybe<Array<UserInfoAccount>>;
};

export class Banner {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
  userCreated?: Maybe<UserAdmin>;
  userDeleted?: Maybe<UserAdmin>;
  userUpdated?: Maybe<UserAdmin>;
};

export class BannerPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Banner>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class CardOfferDto {
  description?: Maybe<Scalars['String']>;
  enterpriseName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  participant?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  urlBannerImage?: Maybe<Scalars['String']>;
};

export class Category {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  enterpriseCategory?: Maybe<Array<EnterpriseCategory>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  offerCategory?: Maybe<Array<OfferCategory>>;
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<UserAdmin>;
  userDeleted?: Maybe<UserAdmin>;
  userUpdated?: Maybe<UserAdmin>;
};

export class CategoryPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Category>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class ChangePasswordAdminInput {
  password: Scalars['String'];
  passwordOld: Scalars['String'];
};

export class ChangePasswordInput {
  password: Scalars['String'];
  passwordOld: Scalars['String'];
};

export class Contact {
  answer?: Maybe<Scalars['String']>;
  answerAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  status: Scalars['String'];
  type?: Maybe<ContactType>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userAnswer?: Maybe<UserAdmin>;
  userDeleted?: Maybe<UserAdmin>;
};

export class ContactPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<Contact>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class ContactType {
  contact?: Maybe<Array<Contact>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<UserAdmin>;
  userDeleted?: Maybe<UserAdmin>;
  userUpdated?: Maybe<UserAdmin>;
};

export class CreateBannerInput {
  end: Scalars['DateTime'];
  isActive: Scalars['Boolean'];
  link?: InputMaybe<Scalars['String']>;
  start: Scalars['DateTime'];
  title: Scalars['String'];
};

export class CreateCategoryInput {
  name: Scalars['String'];
};

export class CreateCompanyAddressInput {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  complement?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export class CreateContactInput {
  description: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  typeId: Scalars['String'];
};

export class CreateEnterpriseInput {
  categories?: InputMaybe<Array<EnterpriseCategoryInput>>;
  cnpj: Scalars['String'];
  curriculum: Scalars['String'];
  description: Scalars['String'];
  email: Scalars['String'];
  goals: Scalars['String'];
  market: Scalars['String'];
  name: Scalars['String'];
  objective: Scalars['String'];
  phone: Scalars['String'];
};

export class CreateEnterpriseMediaVideoInput {
  enterpriseId: Scalars['String'];
  urlVideo: Scalars['String'];
};

export class CreateEnterpriseTeamInput {
  enterpriseId: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['String'];
};

export class CreateInfoAdditionalInput {
  accountPurpose?: InputMaybe<Scalars['String']>;
  estimatedValue?: InputMaybe<Scalars['String']>;
  fundsSource?: InputMaybe<Scalars['String']>;
  transactionFrequency?: InputMaybe<Scalars['String']>;
};

export class CreateNotificationInput {
  link?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export class CreateOfferDocumentsInput {
  offerId: Scalars['String'];
  title: Scalars['String'];
};

export class CreateOfferInput {
  categories?: InputMaybe<Array<OfferCategoryInput>>;
  curriculum: Scalars['String'];
  description: Scalars['String'];
  enterpriseId: Scalars['String'];
  goals: Scalars['String'];
  isOpen: Scalars['Boolean'];
  market: Scalars['String'];
  minimumValue: Scalars['Float'];
  name: Scalars['String'];
  numberOfQuotas: Scalars['Float'];
  objective: Scalars['String'];
  objectiveValue: Scalars['Float'];
  percentageOfParticipation: Scalars['Float'];
  status: Scalars['String'];
  urlVideo?: InputMaybe<Scalars['String']>;
  valuePerQuotas: Scalars['Float'];
};

export class CreateOfferMaterialsInput {
  description: Scalars['String'];
  offerId: Scalars['String'];
  title: Scalars['String'];
};

export class CreateOfferMediaVideoInput {
  offerId: Scalars['String'];
  urlVideo: Scalars['String'];
};

export class CreateOfferTeamInput {
  name: Scalars['String'];
  offerId: Scalars['String'];
  role: Scalars['String'];
};

export class CreatePostCommentInput {
  comment: Scalars['String'];
  postId: Scalars['String'];
};

export class CreatePostInput {
  enterpriseId: Scalars['String'];
  isActive: Scalars['Boolean'];
  text: Scalars['String'];
};

export class CreatePostMediaVideoInput {
  postId: Scalars['String'];
  urlVideo: Scalars['String'];
};

export class CreateTermOfUseInput {
  term: Scalars['String'];
  type: Scalars['String'];
};

export class CreateUserAddressInput {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  complement?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export class CreateUserAdminInput {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  status: Scalars['String'];
};

export class CreateUserInfoAccountInput {
  accountDigit?: InputMaybe<Scalars['String']>;
  accountHolderCpf?: InputMaybe<Scalars['String']>;
  accountHolderName?: InputMaybe<Scalars['String']>;
  accountNumber?: InputMaybe<Scalars['String']>;
  accountType?: InputMaybe<Scalars['String']>;
  agency?: InputMaybe<Scalars['String']>;
  bankId?: InputMaybe<Scalars['String']>;
};

export class CreateUserInfoProfessionalInput {
  companyName?: InputMaybe<Scalars['String']>;
  companyNationality?: InputMaybe<Scalars['String']>;
  jobRole?: InputMaybe<Scalars['String']>;
  payRange?: InputMaybe<Scalars['String']>;
  workRegimeId?: InputMaybe<Scalars['String']>;
};

export class CreateUserInput {
  cpf: Scalars['String'];
  email: Scalars['String'];
  enterpriseId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
};

export class DefaultMessage {
  message: Scalars['String'];
  status: Scalars['Int'];
};

export class Enterprise {
  cnpj: Scalars['String'];
  createdAt: Scalars['DateTime'];
  curriculum: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  email: Scalars['String'];
  enterpriseCategory?: Maybe<Array<EnterpriseCategory>>;
  enterpriseMedia?: Maybe<Array<EnterpriseMedia>>;
  enterpriseTeam?: Maybe<Array<EnterpriseTeam>>;
  goals: Scalars['String'];
  id: Scalars['ID'];
  market: Scalars['String'];
  name: Scalars['String'];
  objective: Scalars['String'];
  offer?: Maybe<Array<Offer>>;
  phone: Scalars['String'];
  post?: Maybe<Array<Post>>;
  updatedAt: Scalars['DateTime'];
  urlBannerImage?: Maybe<Scalars['String']>;
  urlLogoImage?: Maybe<Scalars['String']>;
  user?: Maybe<Array<User>>;
  userCreated?: Maybe<UserAdmin>;
  userDeleted?: Maybe<UserAdmin>;
  userUpdated?: Maybe<UserAdmin>;
};

export class EnterpriseCategory {
  category: Category;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  enterprise: Enterprise;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export class EnterpriseCategoryInput {
  categoryId: Scalars['String'];
};

export class EnterpriseDetailInvestmentFundsDto {
  minimumValue?: Maybe<Scalars['Float']>;
  objectiveValue?: Maybe<Scalars['Float']>;
  totalInvestment?: Maybe<Scalars['Float']>;
};

export class EnterpriseDto {
  curriculum?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  goals?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  market?: Maybe<Scalars['String']>;
  media?: Maybe<Array<EnterpriseMediaDto>>;
  name?: Maybe<Scalars['String']>;
  objective?: Maybe<Scalars['String']>;
  team?: Maybe<Array<EnterpriseTeamDto>>;
  urlBannerImage?: Maybe<Scalars['String']>;
  urlLogoImage?: Maybe<Scalars['String']>;
};

export class EnterpriseInvestorsByOfferDto {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
};

export class EnterpriseMedia {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  enterprise?: Maybe<Enterprise>;
  extensionImage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  originalImageName?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
  urlVideo?: Maybe<Scalars['String']>;
  userCreated?: Maybe<UserAdmin>;
  userDeleted?: Maybe<UserAdmin>;
  userUpdated?: Maybe<UserAdmin>;
  videoOf?: Maybe<Scalars['String']>;
};

export class EnterpriseMediaDto {
  extensionImage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  originalImageName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  urlImage?: Maybe<Scalars['String']>;
  urlVideo?: Maybe<Scalars['String']>;
  videoOf?: Maybe<Scalars['String']>;
};

export class EnterpriseMyCapitalDto {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  quotas?: Maybe<Scalars['Float']>;
  urlLogoImage?: Maybe<Scalars['String']>;
};

export class EnterpriseOfferSummaryDto {
  numberOfQuotas: Scalars['Int'];
  offers: Array<OfferSummaryDto>;
  totalParticipants: Scalars['Int'];
  totalSoldQuotas: Scalars['Int'];
};

export class EnterprisePage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Enterprise>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class EnterpriseSummaryWebDto {
  investmentFunds?: Maybe<EnterpriseDetailInvestmentFundsDto>;
  investors?: Maybe<Array<EnterpriseInvestorsByOfferDto>>;
  offerId: Scalars['ID'];
  offerName?: Maybe<Scalars['String']>;
};

export class EnterpriseTeam {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  enterprise: Enterprise;
  id: Scalars['ID'];
  name: Scalars['String'];
  role: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlPhotoImage?: Maybe<Scalars['String']>;
  userCreated?: Maybe<UserAdmin>;
  userDeleted?: Maybe<UserAdmin>;
  userUpdated?: Maybe<UserAdmin>;
};

export class EnterpriseTeamDto {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  urlPhotoImage?: Maybe<Scalars['String']>;
};

export class EnumType {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  detail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export class FirstAccessEnterpriseInput {
  cnpj: Scalars['String'];
  password: Scalars['String'];
};

export class FirstAccessInput {
  birthday: Scalars['DateTime'];
  companyAddress?: InputMaybe<CreateCompanyAddressInput>;
  cpf: Scalars['String'];
  documentExpirationDate?: InputMaybe<Scalars['DateTime']>;
  documentIssueDate?: InputMaybe<Scalars['DateTime']>;
  documentNumber?: InputMaybe<Scalars['String']>;
  gender: Scalars['String'];
  nationality: Scalars['String'];
  nickname: Scalars['String'];
  password: Scalars['String'];
  userAddress?: InputMaybe<CreateUserAddressInput>;
  userInfoAccount?: InputMaybe<CreateUserInfoAccountInput>;
  userInfoAdditional?: InputMaybe<CreateInfoAdditionalInput>;
  userInfoProfessional?: InputMaybe<CreateUserInfoProfessionalInput>;
};

export class InvestorsByOfferDto {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
};

export class MonthGroupDto {
  month: Scalars['String'];
  posts: Array<PostDto>;
};

export class Mutation {
  buyQuota: DefaultMessage;
  changeAdminPassword: DefaultMessage;
  changePassword: DefaultMessage;
  createBanner: Banner;
  createCategory: Category;
  /** Create new Contact */
  createContact: Contact;
  createEnterprise: Enterprise;
  createEnterpriseMediaVideo: EnterpriseMedia;
  createEnterpriseTeam: EnterpriseTeam;
  createNotification: DefaultMessage;
  createOffer: Offer;
  /** Create a new Offer Document */
  createOfferDocuments: OfferDocuments;
  createOfferMaterials: OfferMaterials;
  createOfferMediaVideo: OfferMedia;
  createOfferTeam: OfferTeam;
  createPost: Post;
  /** Create user comment in post */
  createPostComment: DefaultMessage;
  /** Create user like in post */
  createPostLike: DefaultMessage;
  createPostMediaVideo: PostMedia;
  /** Create a new Term Of Use */
  createTermOfUse: DefaultMessage;
  /** Create new User */
  createUser: User;
  /** Create new User Admin */
  createUserAdmin: UserAdmin;
  /** Delete All User notifications */
  deleteAllNotifications: DefaultMessage;
  /** Remove Category By Id */
  deleteCategory: DefaultMessage;
  /** Delete Contact in Admin by Id */
  deleteContact: DefaultMessage;
  /** Remove Enterprise By Id In Admin */
  deleteEnterprise: DefaultMessage;
  /** Remove Enterprise Media Item By Id In Admin */
  deleteEnterpriseMediaItem: DefaultMessage;
  /** Remove Enterprise Team By Id */
  deleteEnterpriseTeam: DefaultMessage;
  /** Remove Notification By Id */
  deleteNotification: DefaultMessage;
  /** Remove Offer By Id */
  deleteOffer: DefaultMessage;
  /** Remove Offer Documents By Id */
  deleteOfferDocuments: DefaultMessage;
  /** Remove Offer Materials By Id */
  deleteOfferMaterials: DefaultMessage;
  /** Remove Offer Media Item By Id In Admin */
  deleteOfferMediaItem: DefaultMessage;
  /** Remove Offer Team By Id */
  deleteOfferTeam: DefaultMessage;
  /** Remove Post By Id */
  deletePost: DefaultMessage;
  /** Remove Post Media Item By Id In Admin */
  deletePostMediaItem: DefaultMessage;
  /** Delete a Term Of Use by Id */
  deleteTermOfUse: DefaultMessage;
  /** Delete a User */
  deleteUser: DefaultMessage;
  /** Delete User Admin by ID */
  deleteUserAdmin: DefaultMessage;
  /** First Access */
  firstAccess: User;
  /** First Access Enterprise */
  firstAccessEnterprise: User;
  forgotAdminPassword: DefaultMessage;
  forgotPassword: DefaultMessage;
  /** Inactive a User */
  inactiveUser: User;
  login: AuthType;
  loginAdmin: AuthTypeAdmin;
  /** Read All User notifications */
  readAllNotifications: DefaultMessage;
  /** Read User notification */
  readNotification: DefaultMessage;
  removeBanner: DefaultMessage;
  /** Resend First Access Email */
  resendFirstAccessEmail: DefaultMessage;
  resetAdminPassword: DefaultMessage;
  resetPassword: DefaultMessage;
  /** Update Contact */
  respondContact: Contact;
  updateBanner: Banner;
  /** Update Category */
  updateCategory: Category;
  /** Update Enterprise In Admin */
  updateEnterprise: Enterprise;
  /** Update Enterprise Media Video */
  updateEnterpriseMediaVideo: EnterpriseMedia;
  /** Update Enterprise Team */
  updateEnterpriseTeam: EnterpriseTeam;
  /** Update Offer */
  updateOffer: Offer;
  /** Update Offer Documents */
  updateOfferDocuments: OfferDocuments;
  /** Update Offer Materials */
  updateOfferMaterials: OfferMaterials;
  /** Update Offer Media Video */
  updateOfferMediaVideo: OfferMedia;
  /** Update Offer Team */
  updateOfferTeam: OfferTeam;
  /** Update Post */
  updatePost: Post;
  /** Update status comment In Admin */
  updateStatusComment: DefaultMessage;
  /** Update a Term Of Use */
  updateTermOfUse: DefaultMessage;
  /** Update a User */
  updateUser: User;
  /** Update User Admin */
  updateUserAdmin: UserAdmin;
};


export class MutationBuyQuotaArgs {
  offerId: Scalars['String'];
};


export class MutationChangeAdminPasswordArgs {
  data: ChangePasswordAdminInput;
};


export class MutationChangePasswordArgs {
  data: ChangePasswordInput;
};


export class MutationCreateBannerArgs {
  data: CreateBannerInput;
};


export class MutationCreateCategoryArgs {
  data: CreateCategoryInput;
};


export class MutationCreateContactArgs {
  data: CreateContactInput;
};


export class MutationCreateEnterpriseArgs {
  data: CreateEnterpriseInput;
};


export class MutationCreateEnterpriseMediaVideoArgs {
  data: CreateEnterpriseMediaVideoInput;
};


export class MutationCreateEnterpriseTeamArgs {
  data: CreateEnterpriseTeamInput;
};


export class MutationCreateNotificationArgs {
  data: CreateNotificationInput;
};


export class MutationCreateOfferArgs {
  data: CreateOfferInput;
};


export class MutationCreateOfferDocumentsArgs {
  data: CreateOfferDocumentsInput;
};


export class MutationCreateOfferMaterialsArgs {
  data: CreateOfferMaterialsInput;
};


export class MutationCreateOfferMediaVideoArgs {
  data: CreateOfferMediaVideoInput;
};


export class MutationCreateOfferTeamArgs {
  data: CreateOfferTeamInput;
};


export class MutationCreatePostArgs {
  data: CreatePostInput;
};


export class MutationCreatePostCommentArgs {
  data: CreatePostCommentInput;
};


export class MutationCreatePostLikeArgs {
  postId: Scalars['String'];
};


export class MutationCreatePostMediaVideoArgs {
  data: CreatePostMediaVideoInput;
};


export class MutationCreateTermOfUseArgs {
  data: CreateTermOfUseInput;
};


export class MutationCreateUserArgs {
  data: CreateUserInput;
};


export class MutationCreateUserAdminArgs {
  data: CreateUserAdminInput;
};


export class MutationDeleteCategoryArgs {
  id: Scalars['String'];
};


export class MutationDeleteContactArgs {
  id: Scalars['String'];
};


export class MutationDeleteEnterpriseArgs {
  id: Scalars['String'];
};


export class MutationDeleteEnterpriseMediaItemArgs {
  id: Scalars['String'];
};


export class MutationDeleteEnterpriseTeamArgs {
  id: Scalars['String'];
};


export class MutationDeleteNotificationArgs {
  notificationId: Scalars['String'];
};


export class MutationDeleteOfferArgs {
  id: Scalars['String'];
};


export class MutationDeleteOfferDocumentsArgs {
  id: Scalars['String'];
};


export class MutationDeleteOfferMaterialsArgs {
  id: Scalars['String'];
};


export class MutationDeleteOfferMediaItemArgs {
  id: Scalars['String'];
};


export class MutationDeleteOfferTeamArgs {
  id: Scalars['String'];
};


export class MutationDeletePostArgs {
  id: Scalars['String'];
};


export class MutationDeletePostMediaItemArgs {
  id: Scalars['String'];
};


export class MutationDeleteTermOfUseArgs {
  id: Scalars['String'];
};


export class MutationDeleteUserAdminArgs {
  id: Scalars['String'];
};


export class MutationFirstAccessArgs {
  data: FirstAccessInput;
};


export class MutationFirstAccessEnterpriseArgs {
  data: FirstAccessEnterpriseInput;
};


export class MutationForgotAdminPasswordArgs {
  email: Scalars['String'];
};


export class MutationForgotPasswordArgs {
  email: Scalars['String'];
  type: Scalars['String'];
};


export class MutationInactiveUserArgs {
  id: Scalars['String'];
};


export class MutationLoginArgs {
  data: AuthInput;
};


export class MutationLoginAdminArgs {
  data: AuthAdminInput;
};


export class MutationReadNotificationArgs {
  notificationId: Scalars['String'];
};


export class MutationRemoveBannerArgs {
  id: Scalars['String'];
};


export class MutationResendFirstAccessEmailArgs {
  email: Scalars['String'];
};


export class MutationResetAdminPasswordArgs {
  data: ResetPasswordAdminInput;
};


export class MutationResetPasswordArgs {
  data: ResetPasswordInput;
};


export class MutationRespondContactArgs {
  data: UpdateContactInput;
};


export class MutationUpdateBannerArgs {
  data: UpdateBannerInput;
};


export class MutationUpdateCategoryArgs {
  data: UpdateCategoryInput;
};


export class MutationUpdateEnterpriseArgs {
  data: UpdateEnterpriseInput;
};


export class MutationUpdateEnterpriseMediaVideoArgs {
  data: UpdateEnterpriseMediaVideoInput;
};


export class MutationUpdateEnterpriseTeamArgs {
  data: UpdateEnterpriseTeamInput;
};


export class MutationUpdateOfferArgs {
  data: UpdateOfferInput;
};


export class MutationUpdateOfferDocumentsArgs {
  data: UpdateOfferDocumentsInput;
};


export class MutationUpdateOfferMaterialsArgs {
  data: UpdateOfferMaterialsInput;
};


export class MutationUpdateOfferMediaVideoArgs {
  data: UpdateOfferMediaVideoInput;
};


export class MutationUpdateOfferTeamArgs {
  data: UpdateOfferTeamInput;
};


export class MutationUpdatePostArgs {
  data: UpdatePostInput;
};


export class MutationUpdateStatusCommentArgs {
  data: UpdateStatusCommentInput;
};


export class MutationUpdateTermOfUseArgs {
  data: UpdateTermOfUseInput;
};


export class MutationUpdateUserArgs {
  data: UpdateUserDtoInput;
};


export class MutationUpdateUserAdminArgs {
  data: UpdateUserAdminInput;
};

export class MyCapitalDto {
  enterprise?: Maybe<Array<EnterpriseMyCapitalDto>>;
  gains?: Maybe<Scalars['Float']>;
  quotas?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export class MyQuotasEnterpriseDto {
  dividends?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  quotas?: Maybe<Scalars['Float']>;
};

export class NotificationDto {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  readAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export class Offer {
  createdAt: Scalars['DateTime'];
  curriculum: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enterprise?: Maybe<Enterprise>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  goals: Scalars['String'];
  id: Scalars['ID'];
  isOpen: Scalars['Boolean'];
  market: Scalars['String'];
  minimumValue?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  numberOfQuotas?: Maybe<Scalars['Float']>;
  objective: Scalars['String'];
  objectiveValue?: Maybe<Scalars['Float']>;
  offerCategory?: Maybe<Array<OfferCategory>>;
  offerDocuments?: Maybe<Array<OfferDocuments>>;
  offerMaterials?: Maybe<Array<OfferMaterials>>;
  offerMedia?: Maybe<Array<OfferMedia>>;
  offerTeam?: Maybe<Array<OfferTeam>>;
  percentageOfParticipation?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlBannerImage?: Maybe<Scalars['String']>;
  userCreated?: Maybe<UserAdmin>;
  userDeleted?: Maybe<UserAdmin>;
  userOfferQuotas?: Maybe<Array<UserOfferQuotas>>;
  userUpdated?: Maybe<UserAdmin>;
  valuePerQuotas?: Maybe<Scalars['Float']>;
};

export class OfferByCategoryDto {
  category?: Maybe<Scalars['String']>;
  categoryId: Scalars['ID'];
  offers?: Maybe<Array<OffersCategoryDto>>;
};

export class OfferCategory {
  category: Category;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offer: Offer;
  updatedAt: Scalars['DateTime'];
};

export class OfferCategoryInput {
  categoryId: Scalars['String'];
};

export class OfferDetailDto {
  curriculum?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  enterpriseName?: Maybe<Scalars['String']>;
  goals?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  investmentFunds?: Maybe<OfferDetailInvestmentFundsDto>;
  investors?: Maybe<Scalars['Float']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  market?: Maybe<Scalars['String']>;
  media?: Maybe<Array<OfferDetailMediaDto>>;
  name?: Maybe<Scalars['String']>;
  numberOfQuotas?: Maybe<Scalars['Float']>;
  objective?: Maybe<Scalars['String']>;
  objectiveValue?: Maybe<Scalars['Float']>;
  team?: Maybe<Array<OfferDetailTeamDto>>;
  totalQuotas?: Maybe<Scalars['Float']>;
  urlBannerImage?: Maybe<Scalars['String']>;
  valuePerQuotas?: Maybe<Scalars['Float']>;
};

export class OfferDetailInvestmentFundsDto {
  minimumValue?: Maybe<Scalars['Float']>;
  objectiveValue?: Maybe<Scalars['Float']>;
  totalInvestment?: Maybe<Scalars['Float']>;
};

export class OfferDetailMediaDto {
  extensionImage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  originalImageName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  urlImage?: Maybe<Scalars['String']>;
  urlVideo?: Maybe<Scalars['String']>;
  videoOf?: Maybe<Scalars['String']>;
};

export class OfferDetailTeamDto {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  urlPhotoImage?: Maybe<Scalars['String']>;
};

export class OfferDocumentMonth {
  month: Scalars['Float'];
  monthName: Scalars['String'];
  results: Array<OfferDto>;
};

export class OfferDocuments {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  extensionMedia?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  offer: Offer;
  originalName?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlMedia?: Maybe<Scalars['String']>;
  userCreated?: Maybe<UserAdmin>;
  userDeleted?: Maybe<UserAdmin>;
  userUpdated?: Maybe<UserAdmin>;
};

export class OfferDocumentsByDateDto {
  offerDocumentMonths: Array<OfferDocumentMonth>;
  year: Scalars['Float'];
};

export class OfferDocumentsDto {
  createdAt?: Maybe<Scalars['DateTime']>;
  extensionMedia?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  originalName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  urlMedia?: Maybe<Scalars['String']>;
};

export class OfferDto {
  offerDocuments: Array<OfferDocumentsDto>;
  offerName: Scalars['String'];
};

export class OfferMaterials {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  extensionMedia?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  offer: Offer;
  originalName?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlMedia?: Maybe<Scalars['String']>;
  userCreated?: Maybe<UserAdmin>;
  userDeleted?: Maybe<UserAdmin>;
  userUpdated?: Maybe<UserAdmin>;
};

export class OfferMedia {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  extensionImage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  offer?: Maybe<Offer>;
  originalImageName?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
  urlVideo?: Maybe<Scalars['String']>;
  userCreated?: Maybe<UserAdmin>;
  userDeleted?: Maybe<UserAdmin>;
  userUpdated?: Maybe<UserAdmin>;
  videoOf?: Maybe<Scalars['String']>;
};

export class OfferPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Offer>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class OfferSummaryDto {
  activeAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: Scalars['String'];
  totalQuotas: Scalars['Int'];
  valueCollected: Scalars['Float'];
};

export class OfferTeam {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  offer: Offer;
  role: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlPhotoImage?: Maybe<Scalars['String']>;
  userCreated?: Maybe<UserAdmin>;
  userDeleted?: Maybe<UserAdmin>;
  userUpdated?: Maybe<UserAdmin>;
};

export class OffersCategoryDto {
  description?: Maybe<Scalars['String']>;
  enterpriseName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  participant?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  urlBannerImage?: Maybe<Scalars['String']>;
};

export class Post {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  enterprise: Enterprise;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  postComment?: Maybe<Array<PostComment>>;
  postLike?: Maybe<Array<PostLike>>;
  postMedia?: Maybe<Array<PostMedia>>;
  qtdComment?: Maybe<Scalars['Float']>;
  qtdCommentApproved?: Maybe<Scalars['Float']>;
  qtdCommentPending?: Maybe<Scalars['Float']>;
  qtdCommentReproved?: Maybe<Scalars['Float']>;
  qtdLike?: Maybe<Scalars['Float']>;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<UserAdmin>;
  userDeleted?: Maybe<UserAdmin>;
  userLiked?: Maybe<Scalars['Boolean']>;
  userUpdated?: Maybe<UserAdmin>;
};

export class PostComment {
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  post?: Maybe<Post>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedStatusAt: Scalars['DateTime'];
  user: User;
  userUpdatedStatus?: Maybe<UserAdmin>;
};

export class PostCommentPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<PostComment>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class PostCommentsDto {
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  userName?: Maybe<Scalars['String']>;
  userPhoto?: Maybe<Scalars['String']>;
};

export class PostDto {
  comments?: Maybe<Array<PostCommentsDto>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  medias?: Maybe<Array<PostMediaDto>>;
  qtdComment?: Maybe<Scalars['Float']>;
  qtdLike?: Maybe<Scalars['Float']>;
  text: Scalars['String'];
  userLiked?: Maybe<Scalars['Boolean']>;
};

export class PostGroupedDto {
  months: Array<MonthGroupDto>;
  year: Scalars['String'];
};

export class PostLike {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  post?: Maybe<Post>;
  user: User;
};

export class PostMedia {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  extensionImage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  originalImageName?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
  urlVideo?: Maybe<Scalars['String']>;
  userCreated?: Maybe<UserAdmin>;
  userDeleted?: Maybe<UserAdmin>;
  userUpdated?: Maybe<UserAdmin>;
  videoOf?: Maybe<Scalars['String']>;
};

export class PostMediaDto {
  id: Scalars['ID'];
  type: Scalars['String'];
  urlImage?: Maybe<Scalars['String']>;
  urlVideo?: Maybe<Scalars['String']>;
  videoOf?: Maybe<Scalars['String']>;
};

export class PostPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Post>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class Query {
  /** Return All Banners For Admin */
  adminBanners: BannerPage;
  /** Return a Bank By Id */
  bank: Bank;
  /** Return All Banks */
  banks: Array<Bank>;
  /** Return Category By ID */
  categoryById: Category;
  /** Return Comment By ID In Admin */
  commentById: PostComment;
  /** Return Contact by Id */
  contact: Contact;
  /** Return Contact Type By Id */
  contactType: ContactType;
  /** Return all Contact Types */
  contactTypes: Array<ContactType>;
  /** Search all Contact paged */
  contacts: ContactPage;
  /** Return User Logged */
  currentUser: User;
  /** Return User Admin Logged */
  currentUserAdmin: UserAdmin;
  /** Return Enterprise By ID In Admin */
  enterpriseById: Enterprise;
  /** Return Enterprise Media Item By ID In Admin */
  enterpriseMediaItemById: EnterpriseMedia;
  /** Return Enterprise Summary By ID In WEB */
  enterpriseOffersSummary: EnterpriseOfferSummaryDto;
  /** Return Enterprise Summary By enterprise In WEB */
  enterpriseSummaryWeb: Array<EnterpriseSummaryWebDto>;
  /** Return Enterprise Team By ID */
  enterpriseTeamById: EnterpriseTeam;
  /** Return Enterprise By ID In WEB */
  enterpriseWebById: EnterpriseDto;
  /** Return All By Type */
  enumByType: Array<EnumType>;
  /** Return Enum Type by Id */
  enumTypeById: EnumType;
  /** Return All Enum Types */
  enumTypes: Array<EnumType>;
  /** Return All Banners */
  findAllBanners: Array<Banner>;
  /** Return Banners By ID */
  findOneBanner: Banner;
  /** Return Card Last Offer in WEB */
  lastOfferCard?: Maybe<CardOfferDto>;
  /** Return All Category List */
  listAllCategory: Array<Category>;
  /** Return All Enterprise List In Admin */
  listAllEnterprise: Array<Enterprise>;
  /** Return all Offer Documents by Year and Month */
  listAllOfferDocumentsByDate: Array<OfferDocumentsByDateDto>;
  /** Return All Offer Materials By Enterprise in WEB */
  listAllOfferMaterials: Array<OfferMaterials>;
  /** Return List History Offer in WEB */
  listHistoryOffer: Array<CardOfferDto>;
  /** Return All Investors By Offer in WEB */
  listInvestorsByOffer: Array<InvestorsByOfferDto>;
  /** Return All Offer Materials By Offer in WEB */
  listMaterialsByOffer: Array<OfferMaterials>;
  /** Return Offers By Enterprise login in WEB */
  listMyOffersByEnterpriseId: Array<CardOfferDto>;
  /** Return Offers By Category in WEB */
  listOffersByCategory: Array<OfferByCategoryDto>;
  /** Return Offers Recommended in WEB */
  listOffersRecommended: Array<CardOfferDto>;
  /** Return All post grouped by enterprise in WEB */
  listPostGrouped: Array<PostGroupedDto>;
  /** Return My Capital User in WEB */
  myCapital: MyCapitalDto;
  /** Return My Quotas User in WEB */
  myQuotasEnterprise: Array<MyQuotasEnterpriseDto>;
  /** Return All Notification By User */
  notificationsByUser: UserNotificationDto;
  /** Return Offer By ID In ADMIN */
  offerById: Offer;
  /** Return Offer By ID In WEB */
  offerDetailById: OfferDetailDto;
  /** Return Offer Document By ID */
  offerDocumentsById: OfferDocuments;
  /** Return Offer Materials By ID */
  offerMaterialsById: OfferMaterials;
  /** Return Offer Media Item By ID In Admin */
  offerMediaItemById: OfferMedia;
  /** Return Offer Team By ID */
  offerTeamById: OfferTeam;
  /** Return Post By ID */
  postById: Post;
  /** Return Post Media Item By ID In Admin */
  postMediaItemById: PostMedia;
  /** Return All Category List Paged */
  searchAllCategory: CategoryPage;
  /** Return All Comments paged In Admin */
  searchAllComments: PostCommentPage;
  /** Return All Enterprise List Paged In Admin */
  searchAllEnterprise: EnterprisePage;
  /** Return All Offer List Paged */
  searchAllOffer: OfferPage;
  /** Return All Post paged In Admin */
  searchAllPost: PostPage;
  /** Return all Users Admin list paged */
  searchAllUserAdmin: UserAdminPage;
  /** Return All Enterprise In WEB */
  showAllEnterprise: Array<Enterprise>;
  /** Return a Term Of Use by Id */
  termOfUse: TermOfUse;
  /** Return a Term Of Use by Type */
  termOfUseByType: Array<TermOfUse>;
  /** Return All Terms of Use */
  termsOfUse: Array<TermOfUse>;
  /** Return User Admin by ID */
  userAdminById: UserAdmin;
  /** Return User By Id */
  userById: User;
  /** Return User Info By Id */
  userInfoById: User;
  /** Return All users paged */
  users: UserPage;
  /** Return All users by profile */
  usersByProfile: Array<User>;
  /** Verify Token */
  verifyFirstAccessToken: User;
  workRegime: WorkRegime;
  /** Return All Work Regimes */
  workRegimes: Array<WorkRegime>;
};


export class QueryAdminBannersArgs {
  data: SearchBannerInput;
};


export class QueryBankArgs {
  id: Scalars['String'];
};


export class QueryCategoryByIdArgs {
  id: Scalars['String'];
};


export class QueryCommentByIdArgs {
  id: Scalars['String'];
};


export class QueryContactArgs {
  id: Scalars['String'];
};


export class QueryContactTypeArgs {
  id: Scalars['String'];
};


export class QueryContactsArgs {
  data: SearchContactInput;
};


export class QueryEnterpriseByIdArgs {
  id: Scalars['String'];
};


export class QueryEnterpriseMediaItemByIdArgs {
  id: Scalars['String'];
};


export class QueryEnterpriseOffersSummaryArgs {
  enterpriseId: Scalars['String'];
};


export class QueryEnterpriseSummaryWebArgs {
  enterpriseId: Scalars['String'];
};


export class QueryEnterpriseTeamByIdArgs {
  id: Scalars['String'];
};


export class QueryEnterpriseWebByIdArgs {
  enterpriseId: Scalars['String'];
};


export class QueryEnumByTypeArgs {
  type: Scalars['String'];
};


export class QueryEnumTypeByIdArgs {
  id: Scalars['String'];
};


export class QueryFindOneBannerArgs {
  id: Scalars['String'];
};


export class QueryLastOfferCardArgs {
  enterpriseId: Scalars['String'];
};


export class QueryListAllOfferDocumentsByDateArgs {
  enterpriseId: Scalars['String'];
};


export class QueryListAllOfferMaterialsArgs {
  enterpriseId: Scalars['String'];
};


export class QueryListHistoryOfferArgs {
  enterpriseId: Scalars['String'];
};


export class QueryListInvestorsByOfferArgs {
  offerId: Scalars['String'];
};


export class QueryListMaterialsByOfferArgs {
  offerId: Scalars['String'];
};


export class QueryListOffersByCategoryArgs {
  data: SearchOfferByCategoryInput;
};


export class QueryListPostGroupedArgs {
  enterpriseId: Scalars['String'];
};


export class QueryOfferByIdArgs {
  id: Scalars['String'];
};


export class QueryOfferDetailByIdArgs {
  offerId: Scalars['String'];
};


export class QueryOfferDocumentsByIdArgs {
  id: Scalars['String'];
};


export class QueryOfferMaterialsByIdArgs {
  id: Scalars['String'];
};


export class QueryOfferMediaItemByIdArgs {
  id: Scalars['String'];
};


export class QueryOfferTeamByIdArgs {
  id: Scalars['String'];
};


export class QueryPostByIdArgs {
  id: Scalars['String'];
};


export class QueryPostMediaItemByIdArgs {
  id: Scalars['String'];
};


export class QuerySearchAllCategoryArgs {
  data: SearchCategoryInput;
};


export class QuerySearchAllCommentsArgs {
  data: SearchPostCommentsInput;
};


export class QuerySearchAllEnterpriseArgs {
  data: SearchEnterpriseInput;
};


export class QuerySearchAllOfferArgs {
  data: SearchOfferInput;
};


export class QuerySearchAllPostArgs {
  data: SearchPostInput;
};


export class QuerySearchAllUserAdminArgs {
  data: SearchUserAdminInput;
};


export class QueryTermOfUseArgs {
  id: Scalars['String'];
};


export class QueryTermOfUseByTypeArgs {
  type: Scalars['String'];
};


export class QueryUserAdminByIdArgs {
  id: Scalars['String'];
};


export class QueryUserByIdArgs {
  id: Scalars['String'];
};


export class QueryUserInfoByIdArgs {
  userId: Scalars['String'];
};


export class QueryUsersArgs {
  data: SearchUserInput;
};


export class QueryUsersByProfileArgs {
  profile: Scalars['String'];
};


export class QueryVerifyFirstAccessTokenArgs {
  token: Scalars['String'];
};


export class QueryWorkRegimeArgs {
  id: Scalars['String'];
};

export class ResetPasswordAdminInput {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export class ResetPasswordInput {
  code: Scalars['String'];
  newPassword: Scalars['String'];
};

export class SearchBannerInput {
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchCategoryInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchContactInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['String']>;
};

export class SearchEnterpriseInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchOfferByCategoryInput {
  keyword?: InputMaybe<Scalars['String']>;
};

export class SearchOfferInput {
  end?: InputMaybe<Scalars['DateTime']>;
  enterpriseId?: InputMaybe<Array<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchPostCommentsInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<Scalars['String']>>;
};

export class SearchPostInput {
  end?: InputMaybe<Scalars['DateTime']>;
  enterpriseId?: InputMaybe<Array<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchUserAdminInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
};

export class SearchUserInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class TermOfUse {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  term: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<UserAdmin>;
  userDeleted?: Maybe<UserAdmin>;
  userUpdated?: Maybe<UserAdmin>;
};

export class UpdateBannerInput {
  end?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateCategoryInput {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export class UpdateCompanyAddressInput {
  address: Scalars['String'];
  city: Scalars['String'];
  complement: Scalars['String'];
  district: Scalars['String'];
  number: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export class UpdateContactInput {
  answer: Scalars['String'];
  id: Scalars['String'];
};

export class UpdateEnterpriseInput {
  categories?: InputMaybe<Array<EnterpriseCategoryInput>>;
  cnpj?: InputMaybe<Scalars['String']>;
  curriculum?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  goals?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  market?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  objective?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export class UpdateEnterpriseMediaVideoInput {
  enterpriseId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  urlVideo?: InputMaybe<Scalars['String']>;
};

export class UpdateEnterpriseTeamInput {
  enterpriseId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

export class UpdateInfoAdditionalInput {
  accountPurpose: Scalars['String'];
  estimatedValue: Scalars['String'];
  fundsSource: Scalars['String'];
  transactionFrequency: Scalars['String'];
};

export class UpdateOfferDocumentsInput {
  id: Scalars['String'];
  offerId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateOfferInput {
  categories?: InputMaybe<Array<OfferCategoryInput>>;
  curriculum?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  enterpriseId?: InputMaybe<Scalars['String']>;
  goals?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isOpen?: InputMaybe<Scalars['Boolean']>;
  market?: InputMaybe<Scalars['String']>;
  minimumValue?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfQuotas?: InputMaybe<Scalars['Float']>;
  objective?: InputMaybe<Scalars['String']>;
  objectiveValue?: InputMaybe<Scalars['Float']>;
  percentageOfParticipation?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
  urlVideo?: InputMaybe<Scalars['String']>;
  valuePerQuotas?: InputMaybe<Scalars['Float']>;
};

export class UpdateOfferMaterialsInput {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  offerId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateOfferMediaVideoInput {
  id: Scalars['String'];
  offerId?: InputMaybe<Scalars['String']>;
  urlVideo?: InputMaybe<Scalars['String']>;
};

export class UpdateOfferTeamInput {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  offerId?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

export class UpdatePostInput {
  enterpriseId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
};

export class UpdateStatusCommentInput {
  commentId: Scalars['String'];
  /** ENUM approved | reproved */
  status: Scalars['String'];
};

export class UpdateTermOfUseInput {
  id: Scalars['String'];
  term: Scalars['String'];
  type: Scalars['String'];
};

export class UpdateUserAddressInput {
  address: Scalars['String'];
  city: Scalars['String'];
  complement: Scalars['String'];
  district: Scalars['String'];
  number: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export class UpdateUserAdminInput {
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['String'];
};

export class UpdateUserDtoInput {
  companyAddress: UpdateCompanyAddressInput;
  id: Scalars['String'];
  user: UpdateUserInput;
  userAddress: UpdateUserAddressInput;
  userInfoAccount: UpdateUserInfoAccountInput;
  userInfoAdditional: UpdateInfoAdditionalInput;
  userInfoProfessional: UpdateUserInfoProfessionalInput;
};

export class UpdateUserInfoAccountInput {
  accountDigit: Scalars['String'];
  accountHolderCpf: Scalars['String'];
  accountHolderName: Scalars['String'];
  accountNumber: Scalars['String'];
  accountType: Scalars['String'];
  agency: Scalars['String'];
  bankId: Scalars['String'];
};

export class UpdateUserInfoProfessionalInput {
  companyName: Scalars['String'];
  companyNationality: Scalars['String'];
  jobRole: Scalars['String'];
  payRange: Scalars['String'];
  workRegimeId: Scalars['String'];
};

export class UpdateUserInput {
  birthday: Scalars['DateTime'];
  cpf: Scalars['String'];
  email: Scalars['String'];
  gender: Scalars['String'];
  name: Scalars['String'];
  nationality: Scalars['String'];
  nickname: Scalars['String'];
  phone: Scalars['String'];
};

export class User {
  acceptedOrNotPoliciesAt?: Maybe<Scalars['DateTime']>;
  acceptedOrNotTermsAt?: Maybe<Scalars['DateTime']>;
  access?: Maybe<Array<UserAccess>>;
  address?: Maybe<Array<UserAddress>>;
  birthday?: Maybe<Scalars['DateTime']>;
  completedFirstAccessAt?: Maybe<Scalars['DateTime']>;
  cpf?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  documentExpirationDate?: Maybe<Scalars['DateTime']>;
  documentIssueDate?: Maybe<Scalars['DateTime']>;
  documentNumber?: Maybe<Scalars['String']>;
  documentPhotoBackUrl?: Maybe<Scalars['String']>;
  documentPhotoFrontUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  enterprise?: Maybe<Enterprise>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  infoAccount?: Maybe<Array<UserInfoAccount>>;
  infoAdditional?: Maybe<Array<UserInfoAdditional>>;
  infoProfessional?: Maybe<Array<UserInfoProfessional>>;
  isAcceptedPolicies: Scalars['Boolean'];
  isAcceptedTerms: Scalars['Boolean'];
  isCompletedFirstAccess?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  nationality?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  passwordChangedAt?: Maybe<Scalars['DateTime']>;
  phone?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  postComment?: Maybe<Array<PostComment>>;
  postLike?: Maybe<Array<PostLike>>;
  profile: Scalars['String'];
  resetPasswordExpiration?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  tokenExpirationDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userNotification?: Maybe<Array<UserNotification>>;
  userOfferQuotas?: Maybe<Array<UserOfferQuotas>>;
  userUpdated?: Maybe<User>;
  verificationPhotoUrl?: Maybe<Scalars['String']>;
  verificationToken?: Maybe<Scalars['String']>;
};

export class UserAccess {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ip: Scalars['String'];
  user: User;
};

export class UserAddress {
  address?: Maybe<Scalars['String']>;
  bankStatementUrl?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  district?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  publicServiceAccountUrl?: Maybe<Scalars['String']>;
  rentalContractUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  taxReturnUrl?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  zipCode?: Maybe<Scalars['String']>;
};

export class UserAdmin {
  access?: Maybe<Array<UserAdminAccess>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  passwordChangedAt?: Maybe<Scalars['DateTime']>;
  photoUrl?: Maybe<Scalars['String']>;
  resetPasswordExpiration?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<UserAdmin>;
  userDeleted?: Maybe<UserAdmin>;
  userUpdated?: Maybe<UserAdmin>;
};

export class UserAdminAccess {
  admin: UserAdmin;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ip: Scalars['String'];
};

export class UserAdminPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<UserAdmin>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class UserInfoAccount {
  accountDigit?: Maybe<Scalars['String']>;
  accountHolderCpf?: Maybe<Scalars['String']>;
  accountHolderName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  agency?: Maybe<Scalars['String']>;
  bank?: Maybe<Bank>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  incomeDeclarationUrl?: Maybe<Scalars['String']>;
  incomeEvidenceUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export class UserInfoAdditional {
  accountPurpose?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  estimatedValue?: Maybe<Scalars['String']>;
  fundsSource?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  transactionFrequency?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export class UserInfoProfessional {
  companyName?: Maybe<Scalars['String']>;
  companyNationality?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  jobRole?: Maybe<Scalars['String']>;
  payRange?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  workRegime: WorkRegime;
};

export class UserNotification {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  readAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export class UserNotificationDto {
  notifications?: Maybe<Array<NotificationDto>>;
  total: Scalars['Int'];
  totalUnread: Scalars['Int'];
};

export class UserOfferQuotas {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offer?: Maybe<Offer>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export class UserPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<User>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class WorkRegime {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  regime: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userInfoProfessional?: Maybe<Array<UserInfoProfessional>>;
};

export type PostPageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, qtdLike?: number | null, qtdComment?: number | null, qtdCommentApproved?: number | null, qtdCommentReproved?: number | null, qtdCommentPending?: number | null, userLiked?: boolean | null, text: string, isActive: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null, postLike?: Array<{ createdAt: any, user: { id: string, name: string } }> | null, enterprise: { id: string, name: string, urlLogoImage?: string | null, urlBannerImage?: string | null }, postMedia?: Array<{ id: string, urlImage?: string | null, urlVideo?: string | null, type: string, extensionImage?: string | null, originalImageName?: string | null }> | null }> | null };

export type OfferMediaFragment = { createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null };

export type PostMediaFragment = { id: string, type: string, urlImage?: string | null, originalImageName?: string | null, extensionImage?: string | null, urlVideo?: string | null, videoOf?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null };

export type PostFragment = { id: string, qtdLike?: number | null, qtdComment?: number | null, qtdCommentApproved?: number | null, qtdCommentReproved?: number | null, qtdCommentPending?: number | null, userLiked?: boolean | null, text: string, isActive: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null, postLike?: Array<{ createdAt: any, user: { id: string, name: string } }> | null, enterprise: { id: string, name: string, urlLogoImage?: string | null, urlBannerImage?: string | null }, postMedia?: Array<{ id: string, urlImage?: string | null, urlVideo?: string | null, type: string, extensionImage?: string | null, originalImageName?: string | null }> | null };

export type AuthTypeAdminFragment = { token: string, user: { createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, photoUrl?: string | null, resetPasswordExpiration?: any | null } };

export type UserInfoFragment = { id: string, name: string, nickname?: string | null, email: string, cpf?: string | null, birthday?: any | null, phone?: string | null, nationality?: string | null, gender?: string | null, createdAt: any, updatedAt: any, address?: Array<{ id: string, zipCode?: string | null, address?: string | null, number?: string | null, district?: string | null, complement?: string | null, city?: string | null, state?: string | null, type?: string | null }> | null, infoProfessional?: Array<{ id: string, jobRole?: string | null, companyName?: string | null, companyNationality?: string | null, payRange?: string | null, workRegime: { id: string, regime: string } }> | null, infoAccount?: Array<{ accountHolderCpf?: string | null, accountHolderName?: string | null, accountType?: string | null, accountDigit?: string | null, accountNumber?: string | null, agency?: string | null, bank?: { id: string, name: string } | null }> | null, infoAdditional?: Array<{ id: string, accountPurpose?: string | null, estimatedValue?: string | null, fundsSource?: string | null, transactionFrequency?: string | null }> | null, userOfferQuotas?: Array<{ id: string, offer?: { id: string, name: string, objectiveValue?: number | null, minimumValue?: number | null, numberOfQuotas?: number | null, valuePerQuotas?: number | null, enterprise?: { id: string, name: string } | null } | null }> | null };

export type UserAdminFragment = { createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, photoUrl?: string | null, resetPasswordExpiration?: any | null };

export type UserFragment = { birthday?: any | null, createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, phone?: string | null, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null };

export type DefaultMessageFragment = { message: string, status: number };

export type EnterpriseTeamFragment = { id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null };

export type EnterpriseCategoryFragment = { id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null } };

export type EnterprisePageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, cnpj: string, name: string, email: string, phone: string, description: string, objective: string, curriculum: string, market: string, goals: string, urlLogoImage?: string | null, urlBannerImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, enterpriseCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null, enterpriseTeam?: Array<{ id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null }> | null, enterpriseMedia?: Array<{ createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null }> | null }> | null };

export type EnterpriseMediaFragment = { createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null };

export type EnterpriseFragment = { id: string, cnpj: string, name: string, email: string, phone: string, description: string, objective: string, curriculum: string, market: string, goals: string, urlLogoImage?: string | null, urlBannerImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, enterpriseCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null, enterpriseTeam?: Array<{ id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null }> | null, enterpriseMedia?: Array<{ createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null }> | null };

export type CategoryFragment = { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null };

export type CategoryPageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null };

export type OfferTeamFragment = { id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null };

export type OfferCategoryFragment = { id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null } };

export type OfferFragment = { id: string, name: string, description: string, objective: string, curriculum: string, market: string, goals: string, urlBannerImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, minimumValue?: number | null, numberOfQuotas?: number | null, objectiveValue?: number | null, percentageOfParticipation?: number | null, valuePerQuotas?: number | null, isOpen: boolean, status: string, enterprise?: { id: string, name: string } | null, offerTeam?: Array<{ id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null }> | null, offerCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null, offerMaterials?: Array<{ id: string, title: string, description: string, extensionMedia?: string | null, urlMedia?: string | null }> | null, offerDocuments?: Array<{ originalName?: string | null, urlMedia?: string | null, id: string, title: string, extensionMedia?: string | null }> | null, offerMedia?: Array<{ createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null }> | null };

export type OfferDocumentsFragment = { originalName?: string | null, urlMedia?: string | null, id: string, title: string, extensionMedia?: string | null };

export type OfferMaterialsFragment = { id: string, title: string, description: string, extensionMedia?: string | null, urlMedia?: string | null };

export type OfferPageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, name: string, description: string, objective: string, curriculum: string, market: string, goals: string, urlBannerImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, minimumValue?: number | null, numberOfQuotas?: number | null, objectiveValue?: number | null, percentageOfParticipation?: number | null, valuePerQuotas?: number | null, isOpen: boolean, status: string, enterprise?: { id: string, name: string } | null, offerTeam?: Array<{ id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null }> | null, offerCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null, offerMaterials?: Array<{ id: string, title: string, description: string, extensionMedia?: string | null, urlMedia?: string | null }> | null, offerDocuments?: Array<{ originalName?: string | null, urlMedia?: string | null, id: string, title: string, extensionMedia?: string | null }> | null, offerMedia?: Array<{ createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null }> | null }> | null };

export type PostCommentPageFragment = { totalCount: number, pageSize: number, totalPage: number, currentPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, comment: string, status: string, createdAt: any, deletedAt?: any | null, post?: { id: string, qtdLike?: number | null, qtdComment?: number | null, qtdCommentApproved?: number | null, qtdCommentReproved?: number | null, qtdCommentPending?: number | null, userLiked?: boolean | null, text: string, isActive: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null, postLike?: Array<{ createdAt: any, user: { id: string, name: string } }> | null, enterprise: { id: string, name: string, urlLogoImage?: string | null, urlBannerImage?: string | null }, postMedia?: Array<{ id: string, urlImage?: string | null, urlVideo?: string | null, type: string, extensionImage?: string | null, originalImageName?: string | null }> | null } | null, user: { birthday?: any | null, createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, phone?: string | null, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null }, userUpdatedStatus?: { createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, photoUrl?: string | null, resetPasswordExpiration?: any | null } | null }> | null };

export type PostCommentFragment = { id: string, comment: string, status: string, createdAt: any, deletedAt?: any | null, post?: { id: string, qtdLike?: number | null, qtdComment?: number | null, qtdCommentApproved?: number | null, qtdCommentReproved?: number | null, qtdCommentPending?: number | null, userLiked?: boolean | null, text: string, isActive: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null, postLike?: Array<{ createdAt: any, user: { id: string, name: string } }> | null, enterprise: { id: string, name: string, urlLogoImage?: string | null, urlBannerImage?: string | null }, postMedia?: Array<{ id: string, urlImage?: string | null, urlVideo?: string | null, type: string, extensionImage?: string | null, originalImageName?: string | null }> | null } | null, user: { birthday?: any | null, createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, phone?: string | null, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null }, userUpdatedStatus?: { createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, photoUrl?: string | null, resetPasswordExpiration?: any | null } | null };

export type TermOfUseFragment = { id: string, createdAt: any, deletedAt?: any | null, term: string, type: string, updatedAt: any, userCreated?: { createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, photoUrl?: string | null, resetPasswordExpiration?: any | null } | null, userDeleted?: { createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, photoUrl?: string | null, resetPasswordExpiration?: any | null } | null, userUpdated?: { createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, photoUrl?: string | null, resetPasswordExpiration?: any | null } | null };

export type EnumTypeFragment = { type: string, id: string, updatedAt: any, deletedAt?: any | null, createdAt: any, value: string, description?: string | null, detail?: string | null };

export type BannerFragment = { id: string, title?: string | null, urlImage?: string | null, start?: any | null, link?: string | null, end?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, isActive: boolean };

export type ContactFragment = { answer?: string | null, answerAt?: any | null, createdAt: any, deletedAt?: any | null, description: string, email: string, id: string, name: string, status: string, updatedAt: any, type?: { id: string, name: string } | null, user?: { id: string, name: string, cpf?: string | null } | null, userAnswer?: { id: string, name: string } | null };

export type ContactPageFragment = { currentPage: number, nextPage: boolean, pageSize: number, previousPage: boolean, totalCount: number, totalPage: number, results: Array<{ answer?: string | null, answerAt?: any | null, createdAt: any, deletedAt?: any | null, description: string, email: string, id: string, name: string, status: string, updatedAt: any, type?: { id: string, name: string } | null, user?: { id: string, name: string, cpf?: string | null } | null, userAnswer?: { id: string, name: string } | null }> };

export type BannerPageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, title?: string | null, urlImage?: string | null, start?: any | null, link?: string | null, end?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, isActive: boolean }> | null };

export type AdminBannersQueryVariables = Exact<{
  input: SearchBannerInput;
}>;


export type AdminBannersQuery = { adminBanners: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, title?: string | null, urlImage?: string | null, start?: any | null, link?: string | null, end?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, isActive: boolean }> | null } };

export type CreateBannerMutationVariables = Exact<{
  data: CreateBannerInput;
}>;


export type CreateBannerMutation = { createBanner: { id: string, title?: string | null, urlImage?: string | null, start?: any | null, link?: string | null, end?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, isActive: boolean } };

export type UpdateBannerMutationVariables = Exact<{
  data: UpdateBannerInput;
}>;


export type UpdateBannerMutation = { updateBanner: { id: string, title?: string | null, urlImage?: string | null, start?: any | null, link?: string | null, end?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, isActive: boolean } };

export type RemoveBannerMutationVariables = Exact<{
  data: Scalars['String'];
}>;


export type RemoveBannerMutation = { removeBanner: { message: string, status: number } };

export type CreateCategoryMutationVariables = Exact<{
  data: CreateCategoryInput;
}>;


export type CreateCategoryMutation = { createCategory: { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null } };

export type UpdateCategoryMutationVariables = Exact<{
  data: UpdateCategoryInput;
}>;


export type UpdateCategoryMutation = { updateCategory: { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null } };

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCategoryMutation = { deleteCategory: { message: string, status: number } };

export type SearchAllCategoryQueryVariables = Exact<{
  data: SearchCategoryInput;
}>;


export type SearchAllCategoryQuery = { searchAllCategory: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null } };

export type SearchAllCommentsQueryVariables = Exact<{
  data: SearchPostCommentsInput;
}>;


export type SearchAllCommentsQuery = { searchAllComments: { totalCount: number, pageSize: number, totalPage: number, currentPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, comment: string, status: string, createdAt: any, deletedAt?: any | null, post?: { id: string, qtdLike?: number | null, qtdComment?: number | null, qtdCommentApproved?: number | null, qtdCommentReproved?: number | null, qtdCommentPending?: number | null, userLiked?: boolean | null, text: string, isActive: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null, postLike?: Array<{ createdAt: any, user: { id: string, name: string } }> | null, enterprise: { id: string, name: string, urlLogoImage?: string | null, urlBannerImage?: string | null }, postMedia?: Array<{ id: string, urlImage?: string | null, urlVideo?: string | null, type: string, extensionImage?: string | null, originalImageName?: string | null }> | null } | null, user: { birthday?: any | null, createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, phone?: string | null, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null }, userUpdatedStatus?: { createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, photoUrl?: string | null, resetPasswordExpiration?: any | null } | null }> | null } };

export type UpdateStatusCommentMutationVariables = Exact<{
  data: UpdateStatusCommentInput;
}>;


export type UpdateStatusCommentMutation = { updateStatusComment: { message: string, status: number } };

export type ListAllCategoryQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAllCategoryQuery = { listAllCategory: Array<{ id: string, name: string }> };

export type ListAllEnterpriseQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAllEnterpriseQuery = { listAllEnterprise: Array<{ id: string, name: string }> };

export type EnumTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type EnumTypesQuery = { enumTypes: Array<{ type: string, id: string, updatedAt: any, deletedAt?: any | null, createdAt: any, value: string, description?: string | null, detail?: string | null }> };

export type EnumByTypeQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type EnumByTypeQuery = { enumByType: Array<{ type: string, id: string, updatedAt: any, deletedAt?: any | null, createdAt: any, value: string, description?: string | null, detail?: string | null }> };

export type EnumTypeByIdQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type EnumTypeByIdQuery = { enumTypeById: { type: string, id: string, updatedAt: any, deletedAt?: any | null, createdAt: any, value: string, description?: string | null, detail?: string | null } };

export type ContactsQueryVariables = Exact<{
  data: SearchContactInput;
}>;


export type ContactsQuery = { contacts: { currentPage: number, nextPage: boolean, pageSize: number, previousPage: boolean, totalCount: number, totalPage: number, results: Array<{ answer?: string | null, answerAt?: any | null, createdAt: any, deletedAt?: any | null, description: string, email: string, id: string, name: string, status: string, updatedAt: any, type?: { id: string, name: string } | null, user?: { id: string, name: string, cpf?: string | null } | null, userAnswer?: { id: string, name: string } | null }> } };

export type ContactTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactTypesQuery = { contactTypes: Array<{ id: string, name: string }> };

export type ContactQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ContactQuery = { contact: { answer?: string | null, answerAt?: any | null, createdAt: any, deletedAt?: any | null, description: string, email: string, id: string, name: string, status: string, updatedAt: any, type?: { id: string, name: string } | null, user?: { id: string, name: string, cpf?: string | null } | null, userAnswer?: { id: string, name: string } | null } };

export type RespondContactMutationVariables = Exact<{
  data: UpdateContactInput;
}>;


export type RespondContactMutation = { respondContact: { answer?: string | null, answerAt?: any | null, createdAt: any, deletedAt?: any | null, description: string, email: string, id: string, name: string, status: string, updatedAt: any, type?: { id: string, name: string } | null, user?: { id: string, name: string, cpf?: string | null } | null, userAnswer?: { id: string, name: string } | null } };

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteContactMutation = { deleteContact: { message: string, status: number } };

export type CreateEnterpriseMediaVideoMutationVariables = Exact<{
  input: CreateEnterpriseMediaVideoInput;
}>;


export type CreateEnterpriseMediaVideoMutation = { createEnterpriseMediaVideo: { createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null } };

export type UpdateEnterpriseMediaVideoMutationVariables = Exact<{
  input: UpdateEnterpriseMediaVideoInput;
}>;


export type UpdateEnterpriseMediaVideoMutation = { updateEnterpriseMediaVideo: { createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null } };

export type DeleteEnterpriseMediaItemMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteEnterpriseMediaItemMutation = { deleteEnterpriseMediaItem: { message: string, status: number } };

export type CreateEnterpriseMutationVariables = Exact<{
  data: CreateEnterpriseInput;
}>;


export type CreateEnterpriseMutation = { createEnterprise: { id: string, cnpj: string, name: string, email: string, phone: string, description: string, objective: string, curriculum: string, market: string, goals: string, urlLogoImage?: string | null, urlBannerImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, enterpriseCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null, enterpriseTeam?: Array<{ id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null }> | null, enterpriseMedia?: Array<{ createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null }> | null } };

export type UpdateEnterpriseMutationVariables = Exact<{
  data: UpdateEnterpriseInput;
}>;


export type UpdateEnterpriseMutation = { updateEnterprise: { id: string, cnpj: string, name: string, email: string, phone: string, description: string, objective: string, curriculum: string, market: string, goals: string, urlLogoImage?: string | null, urlBannerImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, enterpriseCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null, enterpriseTeam?: Array<{ id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null }> | null, enterpriseMedia?: Array<{ createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null }> | null } };

export type DeleteEnterpriseMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteEnterpriseMutation = { deleteEnterprise: { message: string, status: number } };

export type SearchAllEnterpriseQueryVariables = Exact<{
  data: SearchEnterpriseInput;
}>;


export type SearchAllEnterpriseQuery = { searchAllEnterprise: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, cnpj: string, name: string, email: string, phone: string, description: string, objective: string, curriculum: string, market: string, goals: string, urlLogoImage?: string | null, urlBannerImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, enterpriseCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null, enterpriseTeam?: Array<{ id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null }> | null, enterpriseMedia?: Array<{ createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null }> | null }> | null } };

export type EnterpriseByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EnterpriseByIdQuery = { enterpriseById: { id: string, cnpj: string, name: string, email: string, phone: string, description: string, objective: string, curriculum: string, market: string, goals: string, urlLogoImage?: string | null, urlBannerImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, enterpriseCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null, enterpriseTeam?: Array<{ id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null }> | null, enterpriseMedia?: Array<{ createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null }> | null } };

export type EnterpriseTeamByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EnterpriseTeamByIdQuery = { enterpriseTeamById: { id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null } };

export type CreateEnterpriseTeamMutationVariables = Exact<{
  data: CreateEnterpriseTeamInput;
}>;


export type CreateEnterpriseTeamMutation = { createEnterpriseTeam: { id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null } };

export type UpdateEnterpriseTeamMutationVariables = Exact<{
  data: UpdateEnterpriseTeamInput;
}>;


export type UpdateEnterpriseTeamMutation = { updateEnterpriseTeam: { id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null } };

export type DeleteEnterpriseTeamMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteEnterpriseTeamMutation = { deleteEnterpriseTeam: { message: string, status: number } };

export type CreatePostMutationVariables = Exact<{
  data: CreatePostInput;
}>;


export type CreatePostMutation = { createPost: { id: string, qtdLike?: number | null, qtdComment?: number | null, qtdCommentApproved?: number | null, qtdCommentReproved?: number | null, qtdCommentPending?: number | null, userLiked?: boolean | null, text: string, isActive: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null, postLike?: Array<{ createdAt: any, user: { id: string, name: string } }> | null, enterprise: { id: string, name: string, urlLogoImage?: string | null, urlBannerImage?: string | null }, postMedia?: Array<{ id: string, urlImage?: string | null, urlVideo?: string | null, type: string, extensionImage?: string | null, originalImageName?: string | null }> | null } };

export type UpdatePostMutationVariables = Exact<{
  data: UpdatePostInput;
}>;


export type UpdatePostMutation = { updatePost: { id: string, qtdLike?: number | null, qtdComment?: number | null, qtdCommentApproved?: number | null, qtdCommentReproved?: number | null, qtdCommentPending?: number | null, userLiked?: boolean | null, text: string, isActive: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null, postLike?: Array<{ createdAt: any, user: { id: string, name: string } }> | null, enterprise: { id: string, name: string, urlLogoImage?: string | null, urlBannerImage?: string | null }, postMedia?: Array<{ id: string, urlImage?: string | null, urlVideo?: string | null, type: string, extensionImage?: string | null, originalImageName?: string | null }> | null } };

export type DeletePostMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePostMutation = { deletePost: { message: string, status: number } };

export type SearchAllPostQueryVariables = Exact<{
  data: SearchPostInput;
}>;


export type SearchAllPostQuery = { searchAllPost: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, qtdLike?: number | null, qtdComment?: number | null, qtdCommentApproved?: number | null, qtdCommentReproved?: number | null, qtdCommentPending?: number | null, userLiked?: boolean | null, text: string, isActive: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null, postLike?: Array<{ createdAt: any, user: { id: string, name: string } }> | null, enterprise: { id: string, name: string, urlLogoImage?: string | null, urlBannerImage?: string | null }, postMedia?: Array<{ id: string, urlImage?: string | null, urlVideo?: string | null, type: string, extensionImage?: string | null, originalImageName?: string | null }> | null }> | null } };

export type PostByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PostByIdQuery = { postById: { id: string, qtdLike?: number | null, qtdComment?: number | null, qtdCommentApproved?: number | null, qtdCommentReproved?: number | null, qtdCommentPending?: number | null, userLiked?: boolean | null, text: string, isActive: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null, postLike?: Array<{ createdAt: any, user: { id: string, name: string } }> | null, enterprise: { id: string, name: string, urlLogoImage?: string | null, urlBannerImage?: string | null }, postMedia?: Array<{ id: string, urlImage?: string | null, urlVideo?: string | null, type: string, extensionImage?: string | null, originalImageName?: string | null }> | null } };

export type DeletePostMediaItemMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePostMediaItemMutation = { deletePostMediaItem: { message: string, status: number } };

export type CreatePostMediaVideoMutationVariables = Exact<{
  input: CreatePostMediaVideoInput;
}>;


export type CreatePostMediaVideoMutation = { createPostMediaVideo: { id: string, type: string, urlImage?: string | null, originalImageName?: string | null, extensionImage?: string | null, urlVideo?: string | null, videoOf?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null } };

export type CreateOfferMediaVideoMutationVariables = Exact<{
  input: CreateOfferMediaVideoInput;
}>;


export type CreateOfferMediaVideoMutation = { createOfferMediaVideo: { createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null } };

export type UpdateOfferMediaVideoMutationVariables = Exact<{
  input: UpdateOfferMediaVideoInput;
}>;


export type UpdateOfferMediaVideoMutation = { updateOfferMediaVideo: { createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null } };

export type DeleteOfferMediaItemMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOfferMediaItemMutation = { deleteOfferMediaItem: { message: string, status: number } };

export type CreateOfferMutationVariables = Exact<{
  data: CreateOfferInput;
}>;


export type CreateOfferMutation = { createOffer: { id: string, name: string, description: string, objective: string, curriculum: string, market: string, goals: string, urlBannerImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, minimumValue?: number | null, numberOfQuotas?: number | null, objectiveValue?: number | null, percentageOfParticipation?: number | null, valuePerQuotas?: number | null, isOpen: boolean, status: string, enterprise?: { id: string, name: string } | null, offerTeam?: Array<{ id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null }> | null, offerCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null, offerMaterials?: Array<{ id: string, title: string, description: string, extensionMedia?: string | null, urlMedia?: string | null }> | null, offerDocuments?: Array<{ originalName?: string | null, urlMedia?: string | null, id: string, title: string, extensionMedia?: string | null }> | null, offerMedia?: Array<{ createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null }> | null } };

export type UpdateOfferMutationVariables = Exact<{
  data: UpdateOfferInput;
}>;


export type UpdateOfferMutation = { updateOffer: { id: string, name: string, description: string, objective: string, curriculum: string, market: string, goals: string, urlBannerImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, minimumValue?: number | null, numberOfQuotas?: number | null, objectiveValue?: number | null, percentageOfParticipation?: number | null, valuePerQuotas?: number | null, isOpen: boolean, status: string, enterprise?: { id: string, name: string } | null, offerTeam?: Array<{ id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null }> | null, offerCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null, offerMaterials?: Array<{ id: string, title: string, description: string, extensionMedia?: string | null, urlMedia?: string | null }> | null, offerDocuments?: Array<{ originalName?: string | null, urlMedia?: string | null, id: string, title: string, extensionMedia?: string | null }> | null, offerMedia?: Array<{ createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null }> | null } };

export type DeleteOfferMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOfferMutation = { deleteOffer: { message: string, status: number } };

export type SearchAllOfferQueryVariables = Exact<{
  data: SearchOfferInput;
}>;


export type SearchAllOfferQuery = { searchAllOffer: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, previousPage: boolean, nextPage: boolean, results?: Array<{ id: string, name: string, description: string, objective: string, curriculum: string, market: string, goals: string, urlBannerImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, minimumValue?: number | null, numberOfQuotas?: number | null, objectiveValue?: number | null, percentageOfParticipation?: number | null, valuePerQuotas?: number | null, isOpen: boolean, status: string, enterprise?: { id: string, name: string } | null, offerTeam?: Array<{ id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null }> | null, offerCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null, offerMaterials?: Array<{ id: string, title: string, description: string, extensionMedia?: string | null, urlMedia?: string | null }> | null, offerDocuments?: Array<{ originalName?: string | null, urlMedia?: string | null, id: string, title: string, extensionMedia?: string | null }> | null, offerMedia?: Array<{ createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null }> | null }> | null } };

export type OfferByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type OfferByIdQuery = { offerById: { id: string, name: string, description: string, objective: string, curriculum: string, market: string, goals: string, urlBannerImage?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null, minimumValue?: number | null, numberOfQuotas?: number | null, objectiveValue?: number | null, percentageOfParticipation?: number | null, valuePerQuotas?: number | null, isOpen: boolean, status: string, enterprise?: { id: string, name: string } | null, offerTeam?: Array<{ id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null }> | null, offerCategory?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, category: { id: string, name: string, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null, offerMaterials?: Array<{ id: string, title: string, description: string, extensionMedia?: string | null, urlMedia?: string | null }> | null, offerDocuments?: Array<{ originalName?: string | null, urlMedia?: string | null, id: string, title: string, extensionMedia?: string | null }> | null, offerMedia?: Array<{ createdAt: any, deletedAt?: any | null, extensionImage?: string | null, id: string, originalImageName?: string | null, type: string, updatedAt: any, urlImage?: string | null, urlVideo?: string | null, videoOf?: string | null }> | null } };

export type CreateOfferTeamMutationVariables = Exact<{
  data: CreateOfferTeamInput;
}>;


export type CreateOfferTeamMutation = { createOfferTeam: { id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null } };

export type UpdateOfferTeamMutationVariables = Exact<{
  data: UpdateOfferTeamInput;
}>;


export type UpdateOfferTeamMutation = { updateOfferTeam: { id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null } };

export type DeleteOfferTeamMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOfferTeamMutation = { deleteOfferTeam: { message: string, status: number } };

export type OfferTeamByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type OfferTeamByIdQuery = { offerTeamById: { id: string, name: string, role: string, createdAt: any, updatedAt: any, deletedAt?: any | null, urlPhotoImage?: string | null } };

export type CreateOfferDocumentsMutationVariables = Exact<{
  input: CreateOfferDocumentsInput;
}>;


export type CreateOfferDocumentsMutation = { createOfferDocuments: { originalName?: string | null, urlMedia?: string | null, id: string, title: string, extensionMedia?: string | null } };

export type UpdateOfferDocumentsMutationVariables = Exact<{
  input: UpdateOfferDocumentsInput;
}>;


export type UpdateOfferDocumentsMutation = { updateOfferDocuments: { originalName?: string | null, urlMedia?: string | null, id: string, title: string, extensionMedia?: string | null } };

export type DeleteOfferDocumentsMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOfferDocumentsMutation = { deleteOfferDocuments: { message: string, status: number } };

export type CreateOfferMaterialsMutationVariables = Exact<{
  input: CreateOfferMaterialsInput;
}>;


export type CreateOfferMaterialsMutation = { createOfferMaterials: { id: string, title: string, description: string, extensionMedia?: string | null, urlMedia?: string | null } };

export type UpdateOfferMaterialsMutationVariables = Exact<{
  input: UpdateOfferMaterialsInput;
}>;


export type UpdateOfferMaterialsMutation = { updateOfferMaterials: { id: string, title: string, description: string, extensionMedia?: string | null, urlMedia?: string | null } };

export type DeleteOfferMaterialsMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOfferMaterialsMutation = { deleteOfferMaterials: { message: string, status: number } };

export type SearchAllTermsOfUseQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchAllTermsOfUseQuery = { termsOfUse: Array<{ id: string, createdAt: any, deletedAt?: any | null, term: string, type: string, updatedAt: any, userCreated?: { createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, photoUrl?: string | null, resetPasswordExpiration?: any | null } | null, userDeleted?: { createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, photoUrl?: string | null, resetPasswordExpiration?: any | null } | null, userUpdated?: { createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, photoUrl?: string | null, resetPasswordExpiration?: any | null } | null }> };

export type CreateTermOfUseMutationVariables = Exact<{
  input: CreateTermOfUseInput;
}>;


export type CreateTermOfUseMutation = { createTermOfUse: { message: string, status: number } };

export type UpdateTermOfUseMutationVariables = Exact<{
  input: UpdateTermOfUseInput;
}>;


export type UpdateTermOfUseMutation = { updateTermOfUse: { message: string, status: number } };

export type DeleteTermOfUseMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type DeleteTermOfUseMutation = { deleteTermOfUse: { message: string, status: number } };

export type LoginAdminMutationVariables = Exact<{
  data: AuthAdminInput;
}>;


export type LoginAdminMutation = { loginAdmin: { token: string, user: { createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, photoUrl?: string | null, resetPasswordExpiration?: any | null } } };

export type CurrentUserAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserAdminQuery = { currentUserAdmin: { createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, photoUrl?: string | null, resetPasswordExpiration?: any | null } };

export type WorkRegimesQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkRegimesQuery = { workRegimes: Array<{ id: string, regime: string }> };

export type BanksQueryVariables = Exact<{ [key: string]: never; }>;


export type BanksQuery = { banks: Array<{ id: string, name: string }> };

export type ForgotAdminPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotAdminPasswordMutation = { forgotAdminPassword: { message: string, status: number } };

export type ResetAdminPasswordMutationVariables = Exact<{
  input: ResetPasswordAdminInput;
}>;


export type ResetAdminPasswordMutation = { resetAdminPassword: { message: string, status: number } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { createUser: { birthday?: any | null, createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, phone?: string | null, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null } };

export type UsersQueryVariables = Exact<{
  data: SearchUserInput;
}>;


export type UsersQuery = { users: { currentPage: number, nextPage: boolean, pageSize: number, previousPage: boolean, totalCount: number, totalPage: number, results?: Array<{ birthday?: any | null, createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, phone?: string | null, photoUrl?: string | null, profile: string, resetPasswordExpiration?: any | null }> | null } };

export type ChangeAdminPasswordMutationVariables = Exact<{
  data: ChangePasswordAdminInput;
}>;


export type ChangeAdminPasswordMutation = { changeAdminPassword: { message: string, status: number } };

export type UserInfoByIdQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type UserInfoByIdQuery = { userInfoById: { id: string, name: string, nickname?: string | null, email: string, cpf?: string | null, birthday?: any | null, phone?: string | null, nationality?: string | null, gender?: string | null, createdAt: any, updatedAt: any, address?: Array<{ id: string, zipCode?: string | null, address?: string | null, number?: string | null, district?: string | null, complement?: string | null, city?: string | null, state?: string | null, type?: string | null }> | null, infoProfessional?: Array<{ id: string, jobRole?: string | null, companyName?: string | null, companyNationality?: string | null, payRange?: string | null, workRegime: { id: string, regime: string } }> | null, infoAccount?: Array<{ accountHolderCpf?: string | null, accountHolderName?: string | null, accountType?: string | null, accountDigit?: string | null, accountNumber?: string | null, agency?: string | null, bank?: { id: string, name: string } | null }> | null, infoAdditional?: Array<{ id: string, accountPurpose?: string | null, estimatedValue?: string | null, fundsSource?: string | null, transactionFrequency?: string | null }> | null, userOfferQuotas?: Array<{ id: string, offer?: { id: string, name: string, objectiveValue?: number | null, minimumValue?: number | null, numberOfQuotas?: number | null, valuePerQuotas?: number | null, enterprise?: { id: string, name: string } | null } | null }> | null } };

export type SearchAllUserAdminQueryVariables = Exact<{
  data: SearchUserAdminInput;
}>;


export type SearchAllUserAdminQuery = { searchAllUserAdmin: { currentPage: number, nextPage: boolean, pageSize: number, previousPage: boolean, totalCount: number, totalPage: number, results?: Array<{ createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, photoUrl?: string | null, resetPasswordExpiration?: any | null }> | null } };

export type DeleteUserAdminMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUserAdminMutation = { deleteUserAdmin: { message: string, status: number } };

export type CreateUserAdminMutationVariables = Exact<{
  data: CreateUserAdminInput;
}>;


export type CreateUserAdminMutation = { createUserAdmin: { createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, photoUrl?: string | null, resetPasswordExpiration?: any | null } };

export type UpdateUserAdminMutationVariables = Exact<{
  data: UpdateUserAdminInput;
}>;


export type UpdateUserAdminMutation = { updateUserAdmin: { createdAt: any, deletedAt?: any | null, email: string, id: string, name: string, photoUrl?: string | null, resetPasswordExpiration?: any | null } };

export const PostFragmentDoc = gql`
    fragment Post on Post {
  id
  qtdLike
  qtdComment
  qtdCommentApproved
  qtdCommentReproved
  qtdCommentPending
  userLiked
  postLike {
    createdAt
    user {
      id
      name
    }
  }
  text
  isActive
  createdAt
  updatedAt
  deletedAt
  enterprise {
    id
    name
    urlLogoImage
    urlBannerImage
  }
  postMedia {
    id
    urlImage
    urlVideo
    type
    extensionImage
    originalImageName
  }
}
    `;
export const PostPageFragmentDoc = gql`
    fragment PostPage on PostPage {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...Post
  }
}
    ${PostFragmentDoc}`;
export const PostMediaFragmentDoc = gql`
    fragment PostMedia on PostMedia {
  id
  type
  urlImage
  originalImageName
  extensionImage
  urlVideo
  videoOf
  createdAt
  updatedAt
  deletedAt
}
    `;
export const UserAdminFragmentDoc = gql`
    fragment UserAdmin on UserAdmin {
  createdAt
  deletedAt
  email
  id
  name
  photoUrl
  resetPasswordExpiration
}
    `;
export const AuthTypeAdminFragmentDoc = gql`
    fragment AuthTypeAdmin on AuthTypeAdmin {
  token
  user {
    ...UserAdmin
  }
}
    ${UserAdminFragmentDoc}`;
export const UserInfoFragmentDoc = gql`
    fragment UserInfo on User {
  id
  name
  nickname
  email
  cpf
  birthday
  phone
  nationality
  gender
  createdAt
  updatedAt
  address {
    id
    zipCode
    address
    number
    district
    complement
    city
    state
    type
  }
  infoProfessional {
    id
    jobRole
    companyName
    companyNationality
    payRange
    workRegime {
      id
      regime
    }
  }
  infoAccount {
    accountHolderCpf
    accountHolderName
    accountType
    accountDigit
    accountNumber
    agency
    bank {
      id
      name
    }
  }
  infoAdditional {
    id
    accountPurpose
    estimatedValue
    fundsSource
    transactionFrequency
  }
  userOfferQuotas {
    id
    offer {
      id
      name
      objectiveValue
      minimumValue
      numberOfQuotas
      valuePerQuotas
      enterprise {
        id
        name
      }
    }
  }
}
    `;
export const DefaultMessageFragmentDoc = gql`
    fragment DefaultMessage on DefaultMessage {
  message
  status
}
    `;
export const CategoryFragmentDoc = gql`
    fragment Category on Category {
  id
  name
  createdAt
  updatedAt
  deletedAt
}
    `;
export const EnterpriseCategoryFragmentDoc = gql`
    fragment EnterpriseCategory on EnterpriseCategory {
  id
  createdAt
  updatedAt
  deletedAt
  category {
    ...Category
  }
}
    ${CategoryFragmentDoc}`;
export const EnterpriseTeamFragmentDoc = gql`
    fragment EnterpriseTeam on EnterpriseTeam {
  id
  name
  role
  createdAt
  updatedAt
  deletedAt
  urlPhotoImage
}
    `;
export const EnterpriseMediaFragmentDoc = gql`
    fragment EnterpriseMedia on EnterpriseMedia {
  createdAt
  deletedAt
  extensionImage
  id
  originalImageName
  type
  updatedAt
  urlImage
  urlVideo
  videoOf
}
    `;
export const EnterpriseFragmentDoc = gql`
    fragment Enterprise on Enterprise {
  id
  cnpj
  name
  email
  phone
  description
  objective
  curriculum
  market
  goals
  urlLogoImage
  urlBannerImage
  createdAt
  updatedAt
  deletedAt
  enterpriseCategory {
    ...EnterpriseCategory
  }
  enterpriseTeam {
    ...EnterpriseTeam
  }
  enterpriseMedia {
    ...EnterpriseMedia
  }
}
    ${EnterpriseCategoryFragmentDoc}
${EnterpriseTeamFragmentDoc}
${EnterpriseMediaFragmentDoc}`;
export const EnterprisePageFragmentDoc = gql`
    fragment EnterprisePage on EnterprisePage {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...Enterprise
  }
}
    ${EnterpriseFragmentDoc}`;
export const CategoryPageFragmentDoc = gql`
    fragment CategoryPage on CategoryPage {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...Category
  }
}
    ${CategoryFragmentDoc}`;
export const OfferTeamFragmentDoc = gql`
    fragment OfferTeam on OfferTeam {
  id
  name
  role
  createdAt
  updatedAt
  deletedAt
  urlPhotoImage
}
    `;
export const OfferCategoryFragmentDoc = gql`
    fragment OfferCategory on OfferCategory {
  id
  category {
    ...Category
  }
  createdAt
  updatedAt
  deletedAt
}
    ${CategoryFragmentDoc}`;
export const OfferMaterialsFragmentDoc = gql`
    fragment OfferMaterials on OfferMaterials {
  id
  title
  description
  extensionMedia
  urlMedia
}
    `;
export const OfferDocumentsFragmentDoc = gql`
    fragment OfferDocuments on OfferDocuments {
  originalName
  urlMedia
  id
  title
  extensionMedia
}
    `;
export const OfferMediaFragmentDoc = gql`
    fragment OfferMedia on OfferMedia {
  createdAt
  deletedAt
  extensionImage
  id
  originalImageName
  type
  updatedAt
  urlImage
  urlVideo
  videoOf
}
    `;
export const OfferFragmentDoc = gql`
    fragment Offer on Offer {
  id
  name
  description
  objective
  curriculum
  market
  goals
  urlBannerImage
  createdAt
  updatedAt
  deletedAt
  minimumValue
  numberOfQuotas
  objectiveValue
  percentageOfParticipation
  valuePerQuotas
  enterprise {
    id
    name
  }
  offerTeam {
    ...OfferTeam
  }
  offerCategory {
    ...OfferCategory
  }
  isOpen
  offerMaterials {
    ...OfferMaterials
  }
  offerDocuments {
    ...OfferDocuments
  }
  offerMedia {
    ...OfferMedia
  }
  status
}
    ${OfferTeamFragmentDoc}
${OfferCategoryFragmentDoc}
${OfferMaterialsFragmentDoc}
${OfferDocumentsFragmentDoc}
${OfferMediaFragmentDoc}`;
export const OfferPageFragmentDoc = gql`
    fragment OfferPage on OfferPage {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...Offer
  }
}
    ${OfferFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  birthday
  createdAt
  deletedAt
  email
  id
  name
  phone
  photoUrl
  profile
  resetPasswordExpiration
}
    `;
export const PostCommentFragmentDoc = gql`
    fragment PostComment on PostComment {
  id
  comment
  status
  createdAt
  deletedAt
  post {
    ...Post
  }
  user {
    ...User
  }
  userUpdatedStatus {
    ...UserAdmin
  }
}
    ${PostFragmentDoc}
${UserFragmentDoc}
${UserAdminFragmentDoc}`;
export const PostCommentPageFragmentDoc = gql`
    fragment PostCommentPage on PostCommentPage {
  totalCount
  pageSize
  totalPage
  currentPage
  previousPage
  nextPage
  results {
    ...PostComment
  }
}
    ${PostCommentFragmentDoc}`;
export const TermOfUseFragmentDoc = gql`
    fragment TermOfUse on TermOfUse {
  id
  createdAt
  deletedAt
  term
  type
  updatedAt
  userCreated {
    ...UserAdmin
  }
  userDeleted {
    ...UserAdmin
  }
  userUpdated {
    ...UserAdmin
  }
}
    ${UserAdminFragmentDoc}`;
export const EnumTypeFragmentDoc = gql`
    fragment EnumType on EnumType {
  type
  id
  updatedAt
  deletedAt
  createdAt
  value
  description
  detail
}
    `;
export const ContactFragmentDoc = gql`
    fragment Contact on Contact {
  answer
  answerAt
  createdAt
  deletedAt
  description
  email
  id
  name
  status
  type {
    id
    name
  }
  updatedAt
  user {
    id
    name
    cpf
  }
  userAnswer {
    id
    name
  }
}
    `;
export const ContactPageFragmentDoc = gql`
    fragment ContactPage on ContactPage {
  currentPage
  nextPage
  pageSize
  previousPage
  results {
    ...Contact
  }
  totalCount
  totalPage
}
    ${ContactFragmentDoc}`;
export const BannerFragmentDoc = gql`
    fragment Banner on Banner {
  id
  title
  urlImage
  start
  link
  end
  createdAt
  updatedAt
  deletedAt
  isActive
}
    `;
export const BannerPageFragmentDoc = gql`
    fragment BannerPage on BannerPage {
  totalCount
  pageSize
  currentPage
  totalPage
  previousPage
  nextPage
  results {
    ...Banner
  }
}
    ${BannerFragmentDoc}`;
export const AdminBannersDocument = gql`
    query adminBanners($input: SearchBannerInput!) {
  adminBanners(data: $input) {
    ...BannerPage
  }
}
    ${BannerPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AdminBannersGQL extends Apollo.Query<AdminBannersQuery, AdminBannersQueryVariables> {
    document = AdminBannersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBannerDocument = gql`
    mutation createBanner($data: CreateBannerInput!) {
  createBanner(data: $data) {
    ...Banner
  }
}
    ${BannerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBannerGQL extends Apollo.Mutation<CreateBannerMutation, CreateBannerMutationVariables> {
    document = CreateBannerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBannerDocument = gql`
    mutation updateBanner($data: UpdateBannerInput!) {
  updateBanner(data: $data) {
    ...Banner
  }
}
    ${BannerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBannerGQL extends Apollo.Mutation<UpdateBannerMutation, UpdateBannerMutationVariables> {
    document = UpdateBannerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveBannerDocument = gql`
    mutation removeBanner($data: String!) {
  removeBanner(id: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveBannerGQL extends Apollo.Mutation<RemoveBannerMutation, RemoveBannerMutationVariables> {
    document = RemoveBannerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCategoryDocument = gql`
    mutation createCategory($data: CreateCategoryInput!) {
  createCategory(data: $data) {
    ...Category
  }
}
    ${CategoryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCategoryGQL extends Apollo.Mutation<CreateCategoryMutation, CreateCategoryMutationVariables> {
    document = CreateCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCategoryDocument = gql`
    mutation updateCategory($data: UpdateCategoryInput!) {
  updateCategory(data: $data) {
    ...Category
  }
}
    ${CategoryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCategoryGQL extends Apollo.Mutation<UpdateCategoryMutation, UpdateCategoryMutationVariables> {
    document = UpdateCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCategoryDocument = gql`
    mutation deleteCategory($id: String!) {
  deleteCategory(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCategoryGQL extends Apollo.Mutation<DeleteCategoryMutation, DeleteCategoryMutationVariables> {
    document = DeleteCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchAllCategoryDocument = gql`
    query searchAllCategory($data: SearchCategoryInput!) {
  searchAllCategory(data: $data) {
    ...CategoryPage
  }
}
    ${CategoryPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchAllCategoryGQL extends Apollo.Query<SearchAllCategoryQuery, SearchAllCategoryQueryVariables> {
    document = SearchAllCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchAllCommentsDocument = gql`
    query searchAllComments($data: SearchPostCommentsInput!) {
  searchAllComments(data: $data) {
    ...PostCommentPage
  }
}
    ${PostCommentPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchAllCommentsGQL extends Apollo.Query<SearchAllCommentsQuery, SearchAllCommentsQueryVariables> {
    document = SearchAllCommentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateStatusCommentDocument = gql`
    mutation updateStatusComment($data: UpdateStatusCommentInput!) {
  updateStatusComment(data: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateStatusCommentGQL extends Apollo.Mutation<UpdateStatusCommentMutation, UpdateStatusCommentMutationVariables> {
    document = UpdateStatusCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListAllCategoryDocument = gql`
    query listAllCategory {
  listAllCategory {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListAllCategoryGQL extends Apollo.Query<ListAllCategoryQuery, ListAllCategoryQueryVariables> {
    document = ListAllCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListAllEnterpriseDocument = gql`
    query listAllEnterprise {
  listAllEnterprise {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListAllEnterpriseGQL extends Apollo.Query<ListAllEnterpriseQuery, ListAllEnterpriseQueryVariables> {
    document = ListAllEnterpriseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnumTypesDocument = gql`
    query EnumTypes {
  enumTypes {
    ...EnumType
  }
}
    ${EnumTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EnumTypesGQL extends Apollo.Query<EnumTypesQuery, EnumTypesQueryVariables> {
    document = EnumTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnumByTypeDocument = gql`
    query EnumByType($input: String!) {
  enumByType(type: $input) {
    ...EnumType
  }
}
    ${EnumTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EnumByTypeGQL extends Apollo.Query<EnumByTypeQuery, EnumByTypeQueryVariables> {
    document = EnumByTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnumTypeByIdDocument = gql`
    query EnumTypeById($input: String!) {
  enumTypeById(id: $input) {
    ...EnumType
  }
}
    ${EnumTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EnumTypeByIdGQL extends Apollo.Query<EnumTypeByIdQuery, EnumTypeByIdQueryVariables> {
    document = EnumTypeByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContactsDocument = gql`
    query contacts($data: SearchContactInput!) {
  contacts(data: $data) {
    ...ContactPage
  }
}
    ${ContactPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ContactsGQL extends Apollo.Query<ContactsQuery, ContactsQueryVariables> {
    document = ContactsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContactTypesDocument = gql`
    query contactTypes {
  contactTypes {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContactTypesGQL extends Apollo.Query<ContactTypesQuery, ContactTypesQueryVariables> {
    document = ContactTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContactDocument = gql`
    query contact($id: String!) {
  contact(id: $id) {
    ...Contact
  }
}
    ${ContactFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ContactGQL extends Apollo.Query<ContactQuery, ContactQueryVariables> {
    document = ContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RespondContactDocument = gql`
    mutation respondContact($data: UpdateContactInput!) {
  respondContact(data: $data) {
    ...Contact
  }
}
    ${ContactFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RespondContactGQL extends Apollo.Mutation<RespondContactMutation, RespondContactMutationVariables> {
    document = RespondContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteContactDocument = gql`
    mutation deleteContact($id: String!) {
  deleteContact(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteContactGQL extends Apollo.Mutation<DeleteContactMutation, DeleteContactMutationVariables> {
    document = DeleteContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateEnterpriseMediaVideoDocument = gql`
    mutation createEnterpriseMediaVideo($input: CreateEnterpriseMediaVideoInput!) {
  createEnterpriseMediaVideo(data: $input) {
    ...EnterpriseMedia
  }
}
    ${EnterpriseMediaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEnterpriseMediaVideoGQL extends Apollo.Mutation<CreateEnterpriseMediaVideoMutation, CreateEnterpriseMediaVideoMutationVariables> {
    document = CreateEnterpriseMediaVideoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEnterpriseMediaVideoDocument = gql`
    mutation updateEnterpriseMediaVideo($input: UpdateEnterpriseMediaVideoInput!) {
  updateEnterpriseMediaVideo(data: $input) {
    ...EnterpriseMedia
  }
}
    ${EnterpriseMediaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEnterpriseMediaVideoGQL extends Apollo.Mutation<UpdateEnterpriseMediaVideoMutation, UpdateEnterpriseMediaVideoMutationVariables> {
    document = UpdateEnterpriseMediaVideoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEnterpriseMediaItemDocument = gql`
    mutation deleteEnterpriseMediaItem($id: String!) {
  deleteEnterpriseMediaItem(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEnterpriseMediaItemGQL extends Apollo.Mutation<DeleteEnterpriseMediaItemMutation, DeleteEnterpriseMediaItemMutationVariables> {
    document = DeleteEnterpriseMediaItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateEnterpriseDocument = gql`
    mutation createEnterprise($data: CreateEnterpriseInput!) {
  createEnterprise(data: $data) {
    ...Enterprise
  }
}
    ${EnterpriseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEnterpriseGQL extends Apollo.Mutation<CreateEnterpriseMutation, CreateEnterpriseMutationVariables> {
    document = CreateEnterpriseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEnterpriseDocument = gql`
    mutation updateEnterprise($data: UpdateEnterpriseInput!) {
  updateEnterprise(data: $data) {
    ...Enterprise
  }
}
    ${EnterpriseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEnterpriseGQL extends Apollo.Mutation<UpdateEnterpriseMutation, UpdateEnterpriseMutationVariables> {
    document = UpdateEnterpriseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEnterpriseDocument = gql`
    mutation deleteEnterprise($id: String!) {
  deleteEnterprise(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEnterpriseGQL extends Apollo.Mutation<DeleteEnterpriseMutation, DeleteEnterpriseMutationVariables> {
    document = DeleteEnterpriseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchAllEnterpriseDocument = gql`
    query searchAllEnterprise($data: SearchEnterpriseInput!) {
  searchAllEnterprise(data: $data) {
    ...EnterprisePage
  }
}
    ${EnterprisePageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchAllEnterpriseGQL extends Apollo.Query<SearchAllEnterpriseQuery, SearchAllEnterpriseQueryVariables> {
    document = SearchAllEnterpriseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnterpriseByIdDocument = gql`
    query enterpriseById($id: String!) {
  enterpriseById(id: $id) {
    ...Enterprise
  }
}
    ${EnterpriseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EnterpriseByIdGQL extends Apollo.Query<EnterpriseByIdQuery, EnterpriseByIdQueryVariables> {
    document = EnterpriseByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnterpriseTeamByIdDocument = gql`
    query enterpriseTeamById($id: String!) {
  enterpriseTeamById(id: $id) {
    ...EnterpriseTeam
  }
}
    ${EnterpriseTeamFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EnterpriseTeamByIdGQL extends Apollo.Query<EnterpriseTeamByIdQuery, EnterpriseTeamByIdQueryVariables> {
    document = EnterpriseTeamByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateEnterpriseTeamDocument = gql`
    mutation createEnterpriseTeam($data: CreateEnterpriseTeamInput!) {
  createEnterpriseTeam(data: $data) {
    ...EnterpriseTeam
  }
}
    ${EnterpriseTeamFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEnterpriseTeamGQL extends Apollo.Mutation<CreateEnterpriseTeamMutation, CreateEnterpriseTeamMutationVariables> {
    document = CreateEnterpriseTeamDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEnterpriseTeamDocument = gql`
    mutation updateEnterpriseTeam($data: UpdateEnterpriseTeamInput!) {
  updateEnterpriseTeam(data: $data) {
    ...EnterpriseTeam
  }
}
    ${EnterpriseTeamFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEnterpriseTeamGQL extends Apollo.Mutation<UpdateEnterpriseTeamMutation, UpdateEnterpriseTeamMutationVariables> {
    document = UpdateEnterpriseTeamDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteEnterpriseTeamDocument = gql`
    mutation deleteEnterpriseTeam($id: String!) {
  deleteEnterpriseTeam(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEnterpriseTeamGQL extends Apollo.Mutation<DeleteEnterpriseTeamMutation, DeleteEnterpriseTeamMutationVariables> {
    document = DeleteEnterpriseTeamDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePostDocument = gql`
    mutation createPost($data: CreatePostInput!) {
  createPost(data: $data) {
    ...Post
  }
}
    ${PostFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePostGQL extends Apollo.Mutation<CreatePostMutation, CreatePostMutationVariables> {
    document = CreatePostDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePostDocument = gql`
    mutation updatePost($data: UpdatePostInput!) {
  updatePost(data: $data) {
    ...Post
  }
}
    ${PostFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePostGQL extends Apollo.Mutation<UpdatePostMutation, UpdatePostMutationVariables> {
    document = UpdatePostDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePostDocument = gql`
    mutation deletePost($id: String!) {
  deletePost(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePostGQL extends Apollo.Mutation<DeletePostMutation, DeletePostMutationVariables> {
    document = DeletePostDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchAllPostDocument = gql`
    query searchAllPost($data: SearchPostInput!) {
  searchAllPost(data: $data) {
    ...PostPage
  }
}
    ${PostPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchAllPostGQL extends Apollo.Query<SearchAllPostQuery, SearchAllPostQueryVariables> {
    document = SearchAllPostDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PostByIdDocument = gql`
    query postById($id: String!) {
  postById(id: $id) {
    ...Post
  }
}
    ${PostFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PostByIdGQL extends Apollo.Query<PostByIdQuery, PostByIdQueryVariables> {
    document = PostByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePostMediaItemDocument = gql`
    mutation deletePostMediaItem($id: String!) {
  deletePostMediaItem(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePostMediaItemGQL extends Apollo.Mutation<DeletePostMediaItemMutation, DeletePostMediaItemMutationVariables> {
    document = DeletePostMediaItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePostMediaVideoDocument = gql`
    mutation createPostMediaVideo($input: CreatePostMediaVideoInput!) {
  createPostMediaVideo(data: $input) {
    ...PostMedia
  }
}
    ${PostMediaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePostMediaVideoGQL extends Apollo.Mutation<CreatePostMediaVideoMutation, CreatePostMediaVideoMutationVariables> {
    document = CreatePostMediaVideoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOfferMediaVideoDocument = gql`
    mutation createOfferMediaVideo($input: CreateOfferMediaVideoInput!) {
  createOfferMediaVideo(data: $input) {
    ...OfferMedia
  }
}
    ${OfferMediaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOfferMediaVideoGQL extends Apollo.Mutation<CreateOfferMediaVideoMutation, CreateOfferMediaVideoMutationVariables> {
    document = CreateOfferMediaVideoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOfferMediaVideoDocument = gql`
    mutation updateOfferMediaVideo($input: UpdateOfferMediaVideoInput!) {
  updateOfferMediaVideo(data: $input) {
    ...OfferMedia
  }
}
    ${OfferMediaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOfferMediaVideoGQL extends Apollo.Mutation<UpdateOfferMediaVideoMutation, UpdateOfferMediaVideoMutationVariables> {
    document = UpdateOfferMediaVideoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOfferMediaItemDocument = gql`
    mutation deleteOfferMediaItem($id: String!) {
  deleteOfferMediaItem(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOfferMediaItemGQL extends Apollo.Mutation<DeleteOfferMediaItemMutation, DeleteOfferMediaItemMutationVariables> {
    document = DeleteOfferMediaItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOfferDocument = gql`
    mutation createOffer($data: CreateOfferInput!) {
  createOffer(data: $data) {
    ...Offer
  }
}
    ${OfferFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOfferGQL extends Apollo.Mutation<CreateOfferMutation, CreateOfferMutationVariables> {
    document = CreateOfferDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOfferDocument = gql`
    mutation updateOffer($data: UpdateOfferInput!) {
  updateOffer(data: $data) {
    ...Offer
  }
}
    ${OfferFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOfferGQL extends Apollo.Mutation<UpdateOfferMutation, UpdateOfferMutationVariables> {
    document = UpdateOfferDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOfferDocument = gql`
    mutation deleteOffer($id: String!) {
  deleteOffer(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOfferGQL extends Apollo.Mutation<DeleteOfferMutation, DeleteOfferMutationVariables> {
    document = DeleteOfferDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchAllOfferDocument = gql`
    query searchAllOffer($data: SearchOfferInput!) {
  searchAllOffer(data: $data) {
    ...OfferPage
  }
}
    ${OfferPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchAllOfferGQL extends Apollo.Query<SearchAllOfferQuery, SearchAllOfferQueryVariables> {
    document = SearchAllOfferDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OfferByIdDocument = gql`
    query offerById($id: String!) {
  offerById(id: $id) {
    ...Offer
  }
}
    ${OfferFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OfferByIdGQL extends Apollo.Query<OfferByIdQuery, OfferByIdQueryVariables> {
    document = OfferByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOfferTeamDocument = gql`
    mutation createOfferTeam($data: CreateOfferTeamInput!) {
  createOfferTeam(data: $data) {
    ...OfferTeam
  }
}
    ${OfferTeamFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOfferTeamGQL extends Apollo.Mutation<CreateOfferTeamMutation, CreateOfferTeamMutationVariables> {
    document = CreateOfferTeamDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOfferTeamDocument = gql`
    mutation updateOfferTeam($data: UpdateOfferTeamInput!) {
  updateOfferTeam(data: $data) {
    ...OfferTeam
  }
}
    ${OfferTeamFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOfferTeamGQL extends Apollo.Mutation<UpdateOfferTeamMutation, UpdateOfferTeamMutationVariables> {
    document = UpdateOfferTeamDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOfferTeamDocument = gql`
    mutation deleteOfferTeam($id: String!) {
  deleteOfferTeam(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOfferTeamGQL extends Apollo.Mutation<DeleteOfferTeamMutation, DeleteOfferTeamMutationVariables> {
    document = DeleteOfferTeamDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OfferTeamByIdDocument = gql`
    query offerTeamById($id: String!) {
  offerTeamById(id: $id) {
    ...OfferTeam
  }
}
    ${OfferTeamFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OfferTeamByIdGQL extends Apollo.Query<OfferTeamByIdQuery, OfferTeamByIdQueryVariables> {
    document = OfferTeamByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOfferDocumentsDocument = gql`
    mutation createOfferDocuments($input: CreateOfferDocumentsInput!) {
  createOfferDocuments(data: $input) {
    ...OfferDocuments
  }
}
    ${OfferDocumentsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOfferDocumentsGQL extends Apollo.Mutation<CreateOfferDocumentsMutation, CreateOfferDocumentsMutationVariables> {
    document = CreateOfferDocumentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOfferDocumentsDocument = gql`
    mutation updateOfferDocuments($input: UpdateOfferDocumentsInput!) {
  updateOfferDocuments(data: $input) {
    ...OfferDocuments
  }
}
    ${OfferDocumentsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOfferDocumentsGQL extends Apollo.Mutation<UpdateOfferDocumentsMutation, UpdateOfferDocumentsMutationVariables> {
    document = UpdateOfferDocumentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOfferDocumentsDocument = gql`
    mutation deleteOfferDocuments($id: String!) {
  deleteOfferDocuments(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOfferDocumentsGQL extends Apollo.Mutation<DeleteOfferDocumentsMutation, DeleteOfferDocumentsMutationVariables> {
    document = DeleteOfferDocumentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOfferMaterialsDocument = gql`
    mutation createOfferMaterials($input: CreateOfferMaterialsInput!) {
  createOfferMaterials(data: $input) {
    ...OfferMaterials
  }
}
    ${OfferMaterialsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOfferMaterialsGQL extends Apollo.Mutation<CreateOfferMaterialsMutation, CreateOfferMaterialsMutationVariables> {
    document = CreateOfferMaterialsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOfferMaterialsDocument = gql`
    mutation updateOfferMaterials($input: UpdateOfferMaterialsInput!) {
  updateOfferMaterials(data: $input) {
    ...OfferMaterials
  }
}
    ${OfferMaterialsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOfferMaterialsGQL extends Apollo.Mutation<UpdateOfferMaterialsMutation, UpdateOfferMaterialsMutationVariables> {
    document = UpdateOfferMaterialsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteOfferMaterialsDocument = gql`
    mutation deleteOfferMaterials($id: String!) {
  deleteOfferMaterials(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOfferMaterialsGQL extends Apollo.Mutation<DeleteOfferMaterialsMutation, DeleteOfferMaterialsMutationVariables> {
    document = DeleteOfferMaterialsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchAllTermsOfUseDocument = gql`
    query SearchAllTermsOfUse {
  termsOfUse {
    ...TermOfUse
  }
}
    ${TermOfUseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchAllTermsOfUseGQL extends Apollo.Query<SearchAllTermsOfUseQuery, SearchAllTermsOfUseQueryVariables> {
    document = SearchAllTermsOfUseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTermOfUseDocument = gql`
    mutation CreateTermOfUse($input: CreateTermOfUseInput!) {
  createTermOfUse(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTermOfUseGQL extends Apollo.Mutation<CreateTermOfUseMutation, CreateTermOfUseMutationVariables> {
    document = CreateTermOfUseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTermOfUseDocument = gql`
    mutation UpdateTermOfUse($input: UpdateTermOfUseInput!) {
  updateTermOfUse(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTermOfUseGQL extends Apollo.Mutation<UpdateTermOfUseMutation, UpdateTermOfUseMutationVariables> {
    document = UpdateTermOfUseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTermOfUseDocument = gql`
    mutation DeleteTermOfUse($input: String!) {
  deleteTermOfUse(id: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTermOfUseGQL extends Apollo.Mutation<DeleteTermOfUseMutation, DeleteTermOfUseMutationVariables> {
    document = DeleteTermOfUseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginAdminDocument = gql`
    mutation loginAdmin($data: AuthAdminInput!) {
  loginAdmin(data: $data) {
    ...AuthTypeAdmin
  }
}
    ${AuthTypeAdminFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginAdminGQL extends Apollo.Mutation<LoginAdminMutation, LoginAdminMutationVariables> {
    document = LoginAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserAdminDocument = gql`
    query currentUserAdmin {
  currentUserAdmin {
    ...UserAdmin
  }
}
    ${UserAdminFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserAdminGQL extends Apollo.Query<CurrentUserAdminQuery, CurrentUserAdminQueryVariables> {
    document = CurrentUserAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WorkRegimesDocument = gql`
    query workRegimes {
  workRegimes {
    id
    regime
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WorkRegimesGQL extends Apollo.Query<WorkRegimesQuery, WorkRegimesQueryVariables> {
    document = WorkRegimesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BanksDocument = gql`
    query banks {
  banks {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BanksGQL extends Apollo.Query<BanksQuery, BanksQueryVariables> {
    document = BanksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgotAdminPasswordDocument = gql`
    mutation forgotAdminPassword($email: String!) {
  forgotAdminPassword(email: $email) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgotAdminPasswordGQL extends Apollo.Mutation<ForgotAdminPasswordMutation, ForgotAdminPasswordMutationVariables> {
    document = ForgotAdminPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetAdminPasswordDocument = gql`
    mutation resetAdminPassword($input: ResetPasswordAdminInput!) {
  resetAdminPassword(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetAdminPasswordGQL extends Apollo.Mutation<ResetAdminPasswordMutation, ResetAdminPasswordMutationVariables> {
    document = ResetAdminPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
  createUser(data: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersDocument = gql`
    query users($data: SearchUserInput!) {
  users(data: $data) {
    currentPage
    nextPage
    pageSize
    previousPage
    totalCount
    totalPage
    results {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    document = UsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangeAdminPasswordDocument = gql`
    mutation changeAdminPassword($data: ChangePasswordAdminInput!) {
  changeAdminPassword(data: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeAdminPasswordGQL extends Apollo.Mutation<ChangeAdminPasswordMutation, ChangeAdminPasswordMutationVariables> {
    document = ChangeAdminPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserInfoByIdDocument = gql`
    query userInfoById($userId: String!) {
  userInfoById(userId: $userId) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserInfoByIdGQL extends Apollo.Query<UserInfoByIdQuery, UserInfoByIdQueryVariables> {
    document = UserInfoByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchAllUserAdminDocument = gql`
    query searchAllUserAdmin($data: SearchUserAdminInput!) {
  searchAllUserAdmin(data: $data) {
    currentPage
    nextPage
    pageSize
    previousPage
    totalCount
    totalPage
    results {
      ...UserAdmin
    }
  }
}
    ${UserAdminFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchAllUserAdminGQL extends Apollo.Query<SearchAllUserAdminQuery, SearchAllUserAdminQueryVariables> {
    document = SearchAllUserAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserAdminDocument = gql`
    mutation deleteUserAdmin($id: String!) {
  deleteUserAdmin(id: $id) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserAdminGQL extends Apollo.Mutation<DeleteUserAdminMutation, DeleteUserAdminMutationVariables> {
    document = DeleteUserAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserAdminDocument = gql`
    mutation createUserAdmin($data: CreateUserAdminInput!) {
  createUserAdmin(data: $data) {
    ...UserAdmin
  }
}
    ${UserAdminFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserAdminGQL extends Apollo.Mutation<CreateUserAdminMutation, CreateUserAdminMutationVariables> {
    document = CreateUserAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserAdminDocument = gql`
    mutation updateUserAdmin($data: UpdateUserAdminInput!) {
  updateUserAdmin(data: $data) {
    ...UserAdmin
  }
}
    ${UserAdminFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserAdminGQL extends Apollo.Mutation<UpdateUserAdminMutation, UpdateUserAdminMutationVariables> {
    document = UpdateUserAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }